// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {logEvent} from "../utils/amplitude";
import {Link} from "gatsby";

import {LIME_4, MIDNIGHT_BLUE} from "../styles/colors";

import {BREAKPOINTS} from "../styles/sizes";
import {type CssSettings} from "../types/types/cssSettings";

export type CTALinkType = "local" | "external";

type Props = {
  link: string,
  text: string,
  linkType?: CTALinkType,
  size?: string,
  amplitudeCtaName?: ?string,
  +decorations?: Array<CssSettings> | CssSettings,
};

function CTAButton(props: Props): React.Node {
  const {link, linkType, text, size, amplitudeCtaName, decorations} = props;

  const sizeStyle = size === "large" ? styles.largeButton : null;

  const ampProps = {
    name: amplitudeCtaName || "",
    linkUrl: link,
  };

  const logAmplitudeEvent = (e: SyntheticInputEvent<HTMLButtonElement>) => {
    if (amplitudeCtaName !== (null || "")) {
      logEvent("click:dot_org_site_cta", {
        ...ampProps,
      });
    }
  };

  // for links that redirect to a specific part of the current page
  if (linkType === "local") {
    return (
      <a
        className={css(styles.button, sizeStyle, decorations)}
        href={link}
        onClick={logAmplitudeEvent}
      >
        {text}
      </a>
    );
  }
  if (linkType === "external") {
    return (
      <a
        className={css(styles.button, sizeStyle, decorations)}
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={logAmplitudeEvent}
      >
        {text}
      </a>
    );
  }

  return (
    <a
      className={css(styles.button, sizeStyle, decorations)}
      href={link}
      onClick={logAmplitudeEvent}
    >
      {text}
    </a>
  );
}

const styles = StyleSheet.create({
  button: {
    display: "inline-block",
    width: "100%",
    textAlign: "center",
    backgroundColor: LIME_4,
    color: MIDNIGHT_BLUE,
    padding: "15px 24px",
    borderRadius: "5px",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "20px",
    // boxShadow: "0px 5px 7px 0px rgb(0 0 20 / 20%)",
    fontWeight: 600,
    transition: "background-color 0.2s, box-shadow 0.2s, margin 0.2s",
    ":hover": {
      margin: "1px 0px -1px 0px",
    },

    [BREAKPOINTS.TABLET_PORTRAIT]: {
      width: "fit-content",
    },
  },
  largeButton: {
    fontSize: "18px",
    lineHeight: "22px",
    padding: "25px 40px",
    textAlign: "center",
    verticalAlign: "middle",
  },
});

export default CTAButton;
