// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {LIGHT_GREY_1} from "../../../styles/colors";
import {
  BREAKPOINTS,
  SIDE_MARGIN,
  SIDE_MARGIN_WIDE,
} from "../../../styles/sizes";

import MapHeader from "./MapHeader";
import Map from ".";
import {
  type ContentfulImageFixed,
  type ContentfulImageFluid,
} from "../../../types/fragments/ContentfulImageFragment";

import {type ContentfulMultiColumnFragment} from "../../../types/__generated__/ContentfulMultiColumnFragment";
import {type ContentfulAidTrackerCampaignFragment} from "../../../types/__generated__/ContentfulAidTrackerCampaignFragment";
import {type ContentfulAidTrackerCampaignFragment_aidTrackerData_aidTrackerCountry_coordinates} from "../../../types/__generated__/ContentfulAidTrackerCampaignFragment";

type Props = {|
  title?: ?string,
  eyebrow?: ?string,
  eyebrowIcon?: ContentfulImageFixed | ContentfulImageFluid,
  description?: ?string,
  statistic?: ContentfulMultiColumnFragment,
  aidTrackerCampaigns: Array<ContentfulAidTrackerCampaignFragment>,
  mapDefaultCenterPosition?: ContentfulAidTrackerCampaignFragment_aidTrackerData_aidTrackerCountry_coordinates,
  mapDefaultZoomLevel?: number,
  hideLegend?: boolean,
  clusterRadius?: number,
|};

function AidTrackerMap(props: Props): React.Node {
  const {
    title,
    eyebrow,
    eyebrowIcon,
    description,
    statistic,
    aidTrackerCampaigns,
    mapDefaultZoomLevel,
    mapDefaultCenterPosition,
    clusterRadius,
    hideLegend = false,
  } = props;

  return (
    <section className={css(styles.section)}>
      <div className={css(styles.map)}>
        <Map
          hideLegend={hideLegend}
          aidTrackerCampaigns={aidTrackerCampaigns}
          zoom={mapDefaultZoomLevel}
          center={{
            lat: mapDefaultCenterPosition?.lat || 0,
            lon: mapDefaultCenterPosition?.lon || 0,
          }}
          clusterRadius={clusterRadius || 50}
        />
      </div>
      <MapHeader
        title={title}
        eyebrow={eyebrow}
        eyebrowIcon={eyebrowIcon}
        description={description}
        statistic={statistic}
      />
    </section>
  );
}

const styles = StyleSheet.create({
  section: {
    background: LIGHT_GREY_1,
  },
  map: {
    width: "100%",
    background: LIGHT_GREY_1,
    height: "324px",
    [BREAKPOINTS.DESKTOP]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: "624px",
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      height: "624px",
    },
  },
});

export default AidTrackerMap;
