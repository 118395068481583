// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import Card from "./Card";
import FastFact from "./FastFact";
import StatisticCard from "./StatisticCard";

import {MIDNIGHT_BLUE, LIGHT_GREY_2, WHITE} from "../../styles/colors";
import {h2} from "../../styles/typography";
import {
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
  BREAKPOINTS,
} from "../../styles/sizes";

import {type CssSettings} from "../../types/types/cssSettings";
import {type ContentfulCardFragment} from "../../types/__generated__/ContentfulCardFragment";
import {type ContentfulFastFactFragment} from "../../types/__generated__/ContentfulFastFactFragment";
import {type ContentfulStatisticCardFragment} from "../../types/__generated__/ContentfulStatisticCardFragment";

type Props = {|
  +headline?: ?string,
  +cards: Array<
    | ContentfulFastFactFragment
    | ContentfulCardFragment
    | ContentfulStatisticCardFragment
  >,
  +showDividerBetweenCards?: ?boolean,
  +decorations?: Array<CssSettings> | CssSettings,
  +cardWrapperDecorations?: Array<CssSettings> | CssSettings,
  +cardDecorations?: Array<CssSettings>,
|};

function MultiColumn(props: Props): React.Node {
  const {
    headline,
    cards,
    showDividerBetweenCards = false,
    decorations,
    cardWrapperDecorations,
    cardDecorations = [],
  } = props;

  const showDivider = showDividerBetweenCards === true;

  let finalCardDecorations = [];
  if (showDivider) {
    finalCardDecorations.push(styles.statisticCard);
  }

  finalCardDecorations = [...finalCardDecorations, ...cardDecorations];

  return (
    <div className={css(styles.section, decorations)}>
      {headline && <h2 className={css(styles.title)}>{headline}</h2>}
      <div
        className={css(
          styles.colContainer,
          showDivider && styles.colContainerDivider,
          cardWrapperDecorations
        )}
      >
        {cards.map((card, index) => {
          let component = null;
          switch (card.__typename) {
            case "ContentfulCard":
              return <Card card={card} key={index} />;
            case "ContentfulFastFact":
              return <FastFact fastFact={card} key={index} />;
            case "ContentfulStatisticCard":
              return (
                <StatisticCard
                  card={card}
                  key={index}
                  decorations={finalCardDecorations}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  section: {
    background: MIDNIGHT_BLUE,
    padding: `45px ${SIDE_MARGIN_MOBILE}`,
    [BREAKPOINTS.DESKTOP]: {
      padding: `85px ${SIDE_MARGIN}`,
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `85px ${SIDE_MARGIN_WIDE}`,
    },
  },
  title: {
    ...h2,
    margin: "26px 0px 35px",
    color: WHITE,
  },
  colContainer: {
    position: "relative",
    display: "inline-flex",
    marginBottom: "25px",
    flexWrap: "wrap",
    gap: "40px",
  },
  colContainerDivider: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    overflow: "hidden",
    borderRadius: "5px",
    gap: "0",
    marginBottom: 0,
    backgroundColor: WHITE,
    boxShadow: "0 0px 20px rgb(0 0 0 / 10%)",

    [BREAKPOINTS.TABLET_PORTRAIT]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
  statisticCard: {
    "::after": {
      position: "absolute",
      top: "50%",
      right: 0,
      content: "''",
      height: "80%",
      width: "1px",
      display: "block",
      transform: "translate(0, -50%)",
      backgroundColor: LIGHT_GREY_2,
    },

    ":nth-child(2n):after": {
      display: "none",
    },

    [BREAKPOINTS.TABLET_PORTRAIT]: {
      ":nth-child(2n):after": {
        display: "block",
      },
      ":last-child:after": {
        display: "none",
      },
    },
  },
});

export default MultiColumn;
