// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {Link} from "gatsby";
import {useState} from "react";
import {Location} from "@reach/router";
import {useIntl} from "gatsby-plugin-react-intl";
import {useStaticQuery, graphql} from "gatsby";
import "wicg-inert";

import flagDE from "../../../assets/flag_DE.svg";
import flagUS from "../../../assets/flag_US.svg";

import {WHITE, TEAL_4, MIDNIGHT_BLUE, TEXT_WHITE} from "../../../styles/colors";
import {
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
  BREAKPOINTS,
} from "../../../styles/sizes";

import {locationPath} from "../../../utils/locationUtils";
import {visuallyHiddenStyle} from "../../../styles/misc";

import AnnouncementBanner from "../AnnouncementBanner";
import MobileNavIcon from "../../MobileNavIcon";
import Image from "../Image";
import CTAButton from "../../CTAButton";
import NavTab from "../../NavTab.jsx";

type ContentfulMenuNavigationWrapperProps = {|
  +showAnnouncementBanner?: boolean | null,
  +mobileMenuOpenCallback?: (open: boolean) => void,
|};

function ContentfulMenuNavigationWrapper(
  props: ContentfulMenuNavigationWrapperProps
): React.Node {
  const intl = useIntl();
  const locale = intl.locale || intl.defaultLocale;
  const [mobileMenuIsOpen, toggleIsOpen] = useState(false);
  const {showAnnouncementBanner, mobileMenuOpenCallback} = props;

  // watch nav open state
  React.useEffect(() => {
    // if nav is open, let's lock body scroll, otherwise set it back to auto
    // the following funky approach to lock the body scroll is due to gatsby
    // ref: https://github.com/willmcpo/body-scroll-lock/issues/154
    if (document.documentElement) {
      document.documentElement.style.overflow = mobileMenuIsOpen
        ? "hidden"
        : "";
    }

    if (document.body) {
      document.body.style.overflow = mobileMenuIsOpen ? "hidden" : "";
    }

    mobileMenuOpenCallback && mobileMenuOpenCallback(mobileMenuIsOpen);
  }, [mobileMenuIsOpen]);

  const localeSlug = locale === "de" ? "/de" : "";
  const flags = {
    de: flagDE,
    "en-US": flagUS,
  };

  const data = useStaticQuery(
    graphql`
      query ContentfulMenuNavigationQuery {
        allContentfulMenuNavigation(
          filter: {contentful_id: {eq: "3dt9zsNq8eR8yorDsViQVf"}}
        ) {
          nodes {
            node_locale
            ...ContentfulMenuNavigationFragment
          }
        }
      }
    `
  );

  const localMenuNav = data.allContentfulMenuNavigation.nodes.find(
    el => el.node_locale === locale
  );

  const {logo, navItems, rightCta, announcement} = localMenuNav;

  const renderNavItems = navItems => {
    return navItems.map(navItem => {
      if (navItem.__typename === "ContentfulPage") {
        return (
          <li
            className={css(styles.menuItemList)}
            key={`nav-menu-item-${navItem.slug}`}
          >
            <Link
              onClick={() => toggleIsOpen(false)}
              className={css(
                styles.menuItem,
                loadDeFont ? styles.menuItemFontDe : null
              )}
              to={`${localeSlug}/${navItem.slug}`}
            >
              {navItem.navigationName}
            </Link>
          </li>
        );
      } else if (navItem.__typename === "ContentfulNavTab") {
        return (
          <li
            className={css(styles.menuItemList)}
            key={`nav-menu-item-${navItem.label}`}
          >
            <NavTab
              label={navItem.label}
              navItems={navItem.navItems}
              localeSlug={localeSlug}
              mobileMenuIsOpen={mobileMenuIsOpen}
              toggleIsOpen={toggleIsOpen}
            />
          </li>
        );
      } else {
        return (
          <li
            className={css(styles.menuItemList)}
            key={`nav-menu-item-${navItem.link}`}
          >
            <Link
              onClick={() => toggleIsOpen(false)}
              className={css(
                styles.menuItem,
                loadDeFont ? styles.menuItemFontDe : null
              )}
              to={`${navItem.link}`}
            >
              {navItem.label}
            </Link>
          </li>
        );
      }
    });
  };

  const announcementContent =
    showAnnouncementBanner === true && announcement ? (
      <AnnouncementBanner
        headline={announcement.headline}
        body={announcement.content}
        cta={announcement.cta}
      />
    ) : null;
  const loadDeFont = locale === "de";
  return (
    <Location>
      {({location: {pathname}}) => (
        <>
          {/* skip to content button */}
          <a
            href="#flexport-org-main-content"
            className={css(styles.skipToContent)}
          >
            {intl.formatMessage({id: "skip_to_content"})}
          </a>
          {/* construct hidden banner and menu, so that we don't need to compute layout padding top */}
          <div
            className={css(styles.placeholder)}
            aria-hidden="true"
            inert="true"
          >
            {announcementContent}
            <div className={css(styles.menu)}>
              <div className={css(styles.mobileMenu)}>
                <Link aria-label="flexport.org" to={`${localeSlug}/`}>
                  <Image image={logo} decorations={[styles.brand]} />
                </Link>
                <div className={css(styles.mobileMenuToggle)}>
                  <MobileNavIcon
                    isOpen={mobileMenuIsOpen}
                    toggleIsOpen={toggleIsOpen}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={css(
              styles.body,
              mobileMenuIsOpen && styles.mobileMenuOpenBody
            )}
          >
            {announcementContent}
            <header
              className={css(
                styles.menu,
                mobileMenuIsOpen && styles.mobileMenuOpenHeader
              )}
            >
              <h1 className={css(styles.visuallyHidden)}>Flexport.org</h1>
              <div className={css(styles.mobileMenu)}>
                <Link aria-label="flexport.org" to={`${localeSlug}/`}>
                  <Image image={logo} decorations={[styles.brand]} />
                </Link>
                <div className={css(styles.mobileMenuToggle)}>
                  <MobileNavIcon
                    isOpen={mobileMenuIsOpen}
                    toggleIsOpen={toggleIsOpen}
                  />
                </div>
              </div>
              <nav
                className={css(mobileMenuIsOpen && styles.mobileMenuOpenNav)}
              >
                <ul
                  className={css(
                    styles.menuItemsList,
                    styles.menuItems,
                    mobileMenuIsOpen && styles.mobileMenuOpen
                  )}
                >
                  {renderNavItems(navItems)}
                  {rightCta && mobileMenuIsOpen && (
                    <li className={css(styles.menuItemList)}>
                      <a
                        onClick={() => toggleIsOpen(false)}
                        className={css(styles.menuItem, styles.contactMobile)}
                        href={rightCta.link}
                      >
                        {rightCta.label}
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
              <div className={css(styles.actionMenu)}>
                <Link
                  className={css(
                    styles.localeSwitch,
                    mobileMenuIsOpen && styles.mobileMenuOpen
                  )}
                  aria-label={intl.formatMessage({
                    id: "header_language_switcher_label",
                  })}
                  to={
                    localeSlug
                      ? locationPath(pathname, locale)
                      : `/de${locationPath(pathname, locale)}`
                  }
                  style={{
                    backgroundImage: `url(${flags[locale]})`,
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {rightCta && (
                  <CTAButton
                    decorations={[styles.contact]}
                    link={rightCta.link}
                    linkType={
                      rightCta.openInNewWindow === true ? "external" : "local"
                    }
                    text={rightCta.label}
                    amplitudeCtaName={rightCta.amplitudeCtaName}
                  />
                )}
              </div>
            </header>
          </div>
        </>
      )}
    </Location>
  );
}

const styles = StyleSheet.create({
  visuallyHidden: {
    ...visuallyHiddenStyle,
  },
  skipToContent: {
    position: "absolute",
    width: "1px",
    height: "1px",
    lineHeight: "48px",
    left: "-10px",
    overflow: "hidden",
    textAlign: "center",
    backgroundColor: WHITE,
    transform: "translate(-50%, 0)",

    ":focus-visible": {
      outline: `3px solid ${TEAL_4}`,
      outlineOffset: "2px",
      minWidth: "150px",
      width: "auto",
      height: "48px",
      left: "50%",
      top: "8px",
      zIndex: 9999,
    },
  },
  placeholder: {
    position: "relative",
    visibility: "hidden",
  },
  body: {
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 999,
  },
  menu: {
    boxSizing: "border-box",
    display: "flex",
    backgroundColor: MIDNIGHT_BLUE,
    color: WHITE,
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: `20px ${SIDE_MARGIN_MOBILE} 20px`,
    flexDirection: "column",
    height: "unset",
    borderBottom: `1px solid rgba(255, 255, 255, .1)`,
    boxShadow: "0 0px 20px rgb(0 0 0 / 10%)",

    [BREAKPOINTS.DESKTOP]: {
      alignItems: "center",
      padding: `0px ${SIDE_MARGIN}`,
      flexDirection: "row",
      height: "75px",
    },

    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `0px ${SIDE_MARGIN_WIDE}`,
    },
  },
  mobileMenu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",

    [BREAKPOINTS.DESKTOP]: {
      justifyContent: "unset",
      width: "auto",
    },
  },
  mobileMenuToggle: {
    display: "flex",
    [BREAKPOINTS.DESKTOP]: {
      display: "none",
    },
  },
  brand: {
    width: "180px",
    minWidth: "180px",
    height: "35px",
  },
  menuItems: {
    display: "none",
    listStyle: "none",
    justifyContent: "space-between",
    padding: "0px",
    margin: "0px",
    fontSize: "14px",
    width: "100%",
    flexDirection: "column",

    [BREAKPOINTS.DESKTOP]: {
      display: "flex",
      width: "auto",
      flexDirection: "row",
    },
  },
  menuItemFontDe: {
    fontSize: "13px",
  },
  menuItemsList: {
    margin: 0,
    listStyle: "none",
  },
  menuItemList: {
    margin: 0,

    [BREAKPOINTS.DESKTOP]: {
      minWidth: "150px",
      margin: "0px 10px",
    },
  },
  mobileMenuOpenBody: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
  },
  mobileMenuOpenHeader: {
    overflowY: "hidden",
  },
  mobileMenuOpenNav: {
    overflowY: "auto",
    width: "100%",
  },
  mobileMenuOpen: {
    display: "block",
    [BREAKPOINTS.DESKTOP]: {
      display: "none",
    },
  },
  menuItem: {
    display: "block",
    margin: "0px 10px",
    color: WHITE,
    textDecoration: "none",
    ":hover": {
      color: TEXT_WHITE,
    },
    margin: "35px 0px",
    ":first-of-type": {
      marginTop: "25px",
    },
    ":last-of-type": {
      marginBottom: "10px",
    },

    [BREAKPOINTS.DESKTOP]: {
      textAlign: "center",
      margin: "0 10px",
      ":first-of-type": {
        marginTop: "0",
      },
      ":last-of-type": {
        marginBottom: "0",
      },
    },
  },
  actionMenu: {
    display: "flex",
    alignItems: "center",
    flexGrow: 0,
    flexShrink: 0,
  },
  contactMobile: {
    display: "block",

    [BREAKPOINTS.DESKTOP]: {
      display: "none",
    },
  },
  contact: {
    display: "none",
    boxShadow: "none",
    lineHeight: 1,
    ":hover": {
      boxShadow: "none",
      margin: "inherit",
    },

    [BREAKPOINTS.DESKTOP]: {
      display: "block",
    },
  },
  localeSwitch: {
    display: "none",
    width: "23px",
    height: "15px",
    margin: "10px 20px 0px 0px",
    color: WHITE,
    textDecoration: "none",

    [BREAKPOINTS.DESKTOP]: {
      marginTop: "0",
      display: "block",
    },
  },
});

export default ContentfulMenuNavigationWrapper;
