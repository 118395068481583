// @flow strict

// flowlint untyped-import:off
import amplitude from "amplitude-js";
// flowlint untyped-import:error

type AdditionalProperties = {};

let isAmplitudeInitialized: boolean = false;
let userProperties = {};

// For enqueuing all events before amplitude sdk is initialized.
const eventQueue: Array<{|
  eventType: EventType,
  properties: $Values<AmplitudeEvents>,
|}> = [];

export function isInitialized(): boolean {
  return isAmplitudeInitialized;
}

export function init(apiKey: string): void {
  amplitude.getInstance().init(
    apiKey,
    null,
    {
      includeUtm: true,
      includeReferrer: true,
    },
    () => {
      isAmplitudeInitialized = true;
      eventQueue.forEach(({eventType, properties}) => {
        amplitude.getInstance().logEvent(eventType, properties);
        amplitude.getInstance().setUserProperties(userProperties);
      });
    }
  );
}

export function setOptOut(shouldOptOutAmplitude: boolean): void {
  if (isAmplitudeInitialized) {
    amplitude.getInstance().setOptOut(shouldOptOutAmplitude);
  }
}

/* Each amplitude event should register its name and
 * properties type in AmplitudeEvents. This helps us ensure
 * we never forget to add properties required by a dashboard
 * in an amplitude event log call.
 */
type AmplitudeEvents = {|
  gatsbyRouteUpdate: {|path: string|},
  "click:dot_org_site_cta": {|
    name: string,
    linkUrl?: ?string,
  |},
|};

export type AmplitudeCtaProps = $ElementType<
  AmplitudeEvents,
  "click:dot_org_site_cta"
>;

type EventType = $Keys<AmplitudeEvents>;
type EventProperty<EventTypeKey> = $ElementType<AmplitudeEvents, EventTypeKey>;

type PromiseResult = {
  responseCode?: number,
  responseBody?: string,
};
/* Log an event to amplitude.

 * Please use this function rather than calling amplitude.logEvent
 * directly. This function is typed to ensure all required amplitude
 * properties are included for each event call.

 * The standard amplitude API accepts a callback as its third argument.
   We differ here by returning a promise instead.
 */
/* eslint-disable-next-line import/prefer-default-export */
export function logEvent<EventTypeKey: EventType>(
  eventType: EventTypeKey,
  properties: EventProperty<EventTypeKey>
): Promise<PromiseResult> {
  if (!isAmplitudeInitialized) {
    eventQueue.push({eventType, properties});
    return Promise.resolve({});
  }

  return new Promise((resolve, reject) => {
    amplitude
      .getInstance()
      .logEvent(
        eventType,
        properties,
        (responseCode: number, responseBody: string) => {
          if (responseCode === 200 && responseBody === "success") {
            resolve({responseCode, responseBody});
          }

          if (responseCode === 0 && responseBody === "No request sent") {
            // TODO: Log this to datadog. This indicates that a user has
            // opted out of tracking.
            resolve({responseCode, responseBody});
          }

          reject(
            new Error(`Attempt to log to amplitude returned ${responseCode}`)
          );
        }
      );
  }).catch((error: PromiseResult) => {
    // Failure to log events to amplitude shouldn't ever result in a user-facing
    // error, so we gotta catch 'em all.
    return error;
  });
}

/* Update user properties to amplitude.

 * Please use this function rather than calling amplitude.setUserProperties
 * directly. This function is typed to ensure all required amplitude
 * properties, including experiments, are included for each event call.
 */
export function updateUserProperties(
  // TODO: type out additional properties, right not it's not been used
  additionalProperties: AdditionalProperties
) {
  const newProperties = {
    /* eslint-enable camelcase */
    ...additionalProperties,
  };

  userProperties = {
    ...userProperties,
    ...newProperties,
  };

  if (isAmplitudeInitialized) {
    amplitude.getInstance().setUserProperties(userProperties);
  }
}
