// @flow strict
import * as React from "react";

// flowlint untyped-import:off
import {Helmet} from "react-helmet-async";
// flowlint untyped-import:error

import * as Amplitude from "../../utils/amplitude";
import GDPRContext from "./GDPRContext";

const AMPLITUDE_API_KEY = process.env.GATSBY_AMPLITUDE_API_KEY;
const GTM_CONTAINER_ID = "UA-40633294-10";

function configureAmplitude(): void {
  try {
    if (AMPLITUDE_API_KEY != null) {
      Amplitude.init(AMPLITUDE_API_KEY);
      Amplitude.updateUserProperties({});
    }
  } catch (error) {
    null;
  }
}

function setAmplitudeOptOut(value: boolean): void {
  Amplitude.setOptOut(value);
}

function TrackingTags(): React.Node {
  const {hasEnabledTracking} = React.useContext(GDPRContext);

  React.useEffect(() => {
    if (hasEnabledTracking) {
      if (!Amplitude.isInitialized()) {
        configureAmplitude();
      }
      return;
    }

    if (!hasEnabledTracking && Amplitude.isInitialized()) {
      setAmplitudeOptOut(true);
      window.location.reload();
    }
  }, [hasEnabledTracking]);

  const pardotTagString = `
    piAId = '926323';
    piCId = '4711';
    piHostname = 'pi.pardot.com';

    (function() {
      function async_load(){
        var s = document.createElement('script'); s.type = 'text/plain';
        s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
        var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
      }
      if(window.attachEvent) { window.attachEvent('onload', async_load); }
      else { window.addEventListener('load', async_load, false); }
    })();
  `;

  const getSiteControlString = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-N47WM58');
  `;

  return (
    <Helmet>
      <script
        type="text/plain"
        data-purposes="analytics"
        src={`https://www.googletagmanager.com/gtag/js?id=${GTM_CONTAINER_ID}`}
      />
      <script type="text/plain" data-purposes="analytics">{`
        window.dataLayer=window.dataLayer||[];
        function gtag(){dataLayer.push(arguments);}
        gtag('js',new Date());
        gtag('config','${GTM_CONTAINER_ID}');
      `}</script>
      <script type="text/plain" data-purposes="behavioral_advertising">
        {pardotTagString}
      </script>
      <script type="text/plain" data-purposes="analytics">
        {getSiteControlString}
      </script>
    </Helmet>
  );
}

export default TrackingTags;
