// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
  BREAKPOINTS,
} from "../../styles/sizes";
import {h2, p} from "../../styles/typography";

import {MIDNIGHT_BLUE, WHITE, LIGHT_GREY_1} from "../../styles/colors";

import {
  type ContentfulImageFixed,
  type ContentfulImageFluid,
} from "../../types/fragments/ContentfulImageFragment";

import {type ContentfulCTAFragment} from "../../types/__generated__/ContentfulCTAFragment";

import Image from "./Image";
import CTAButton from "../CTAButton";

type Props = {|
  +headline?: ?string,
  +description?: ?string,
  +image?: ContentfulImageFixed | ContentfulImageFluid,
  +cta?: ?ContentfulCTAFragment,
|};

function GetStartedModule(props: Props): React.Node {
  const {headline, description, image, cta} = props;

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.contentWrapper)}>
        {headline && <h2 className={css(styles.headline)}>{headline}</h2>}
        {description && (
          <p className={css(styles.description)}>{description}</p>
        )}
        {cta && (
          <CTAButton
            text={cta.label || ""}
            link={cta.link || ""}
            amplitudeCtaName={cta.amplitudeCtaName || ""}
          />
        )}
      </div>
      {image && (
        <Image
          image={image}
          objectFit={"cover"}
          imageWrapperDecorations={styles.imageWrapper}
          decorations={styles.image}
        />
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",

    background: MIDNIGHT_BLUE,

    [BREAKPOINTS.DESKTOP]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `0 ${SIDE_MARGIN_WIDE}`,
    },
  },
  contentWrapper: {
    padding: `40px ${SIDE_MARGIN_MOBILE}`,
    [BREAKPOINTS.DESKTOP]: {
      maxWidth: "600px",
      boxSizing: "content-box",
      marginRight: "40px",
      padding: "100px 0",
      paddingLeft: SIDE_MARGIN,
    },

    [BREAKPOINTS.DESKTOP_LARGE]: {
      paddingLeft: 0,
    },
  },
  headline: {
    ...h2,
    color: WHITE,
    marginBottom: "25px",
  },
  description: {
    ...p,
    color: LIGHT_GREY_1,
    fontSize: "16px",

    marginBottom: "32px",
  },
  imageWrapper: {
    flexBasis: "40%",
  },
  image: {
    width: "100%",
    maxHeight: "224px",

    [BREAKPOINTS.DESKTOP]: {
      maxHeight: "unset",
      height: "100%",
      width: "auto",
    },
  },
});

export default GetStartedModule;
