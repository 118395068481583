// @flow

import * as React from "react";
import type {CssSettings} from "../../../types/types/cssSettings";
import {css, StyleSheet} from "aphrodite";
import type {MapProps} from "./MapBox.jsx";
import {LIGHT_GREY_1} from "../../../styles/colors";

const MapBoxLazy = React.lazy(() => import("./MapBox"));

const Map = (props: MapProps): React.Node => {
  const isSSR = typeof window === "undefined";
  const FallBackComponent = () => (
    <div className={css(styles.fallBackContainer)} />
  );
  return (
    <>
      {!isSSR ? (
        <React.Suspense fallback={<FallBackComponent />}>
          <MapBoxLazy {...props} />
        </React.Suspense>
      ) : (
        <FallBackComponent />
      )}
    </>
  );
};
export default Map;

const styles = StyleSheet.create({
  fallBackContainer: {
    width: "100%",
    height: "100%",
    height: "100%",
    backgroundColor: LIGHT_GREY_1,
  },
});
