// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {MIDNIGHT_BLUE, OFF_WHITE, WHITE} from "../../styles/colors";
import {h2} from "../../styles/typography";
import {
  BREAKPOINTS,
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
} from "../../styles/sizes";
import type {
  ContentfulImageFixed,
  ContentfulImageFluid,
} from "../../types/fragments/ContentfulImageFragment";
import Image from "./Image.jsx";

type Props = {
  darkmode?: boolean,
  headline: string,
  logos: Array<ContentfulImageFixed | ContentfulImageFluid>,
};

function LogoShowcase(props: Props): React.Node {
  const {darkmode, headline, logos} = props;
  const style = darkmode ? styles.dark : "";
  return (
    <div className={css(styles.section, style)}>
      <h1 className={css(styles.title, style)}>{headline}</h1>
      <div className={css(styles.logoContainer)}>
        {logos.map((logo, index) => (
          <div key={index}>
            <Image
              image={logo}
              decorations={
                !darkmode ? [styles.logo, styles.logoDark] : styles.logo
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  section: {
    background: OFF_WHITE,
    padding: `45px ${SIDE_MARGIN_MOBILE}`,
    [`${BREAKPOINTS.DESKTOP}`]: {
      padding: `85px ${SIDE_MARGIN}`,
    },
    [`${BREAKPOINTS.DESKTOP_LARGE}`]: {
      padding: `85px ${SIDE_MARGIN_WIDE}`,
    },
  },
  title: {
    ...h2,
    color: MIDNIGHT_BLUE,
    margin: "21px 0px 45px",
  },
  logoContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "none",
    [`${BREAKPOINTS.DESKTOP}`]: {
      marginLeft: "-50px",
      justifyContent: "center",
    },
  },
  logo: {
    margin: "20px 50px",
    width: "150px",
  },
  logoDark: {
    filter: "grayscale(100%) contrast(90%) opacity(80%)",
  },
  dark: {
    background: MIDNIGHT_BLUE,
    color: WHITE,
    filter: "unset",
  },
});

export default LogoShowcase;
