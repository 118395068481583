// @flow

import DOMPurify from "isomorphic-dompurify";

type AllowedAttributes = {
  [string]: Array<string>,
};

type SanitizeOptions = {
  allowedTags?: Array<string>,
  allowedAttributes: AllowedAttributes,
};

// utility method to sanitize HTML string
export const sanitizeDom = (
  dirtyContent: string,
  options: SanitizeOptions
): string => {
  const {allowedAttributes, allowedTags} = options;
  if (allowedAttributes) {
    // to filter allowed attributes on each nodes
    DOMPurify.addHook("afterSanitizeAttributes", node => {
      let conf = allowedAttributes["*"] || [];
      const tagName = node.tagName.toLowerCase();
      if (allowedAttributes[tagName]) {
        conf = [...new Set([...conf, ...allowedAttributes[tagName]])];
      }

      if (conf.length) {
        // check if any attributes not in the list
        const nodeAttrs = node.attributes;

        const allowedAttrsRegex = new RegExp(conf.join("|"), "gi");

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < nodeAttrs.length; i++) {
          const t = nodeAttrs[i];
          if (t && !t.name.match(allowedAttrsRegex)) {
            // remove from node
            node.removeAttribute(t.name);
          }
        }
      }
    });
  }

  return DOMPurify.sanitize(dirtyContent, {
    ADD_TAGS: allowedTags || [],
    // this is to allow target attribute by default, we can remove it by explicitly defining
    // allowed attributes on tag level.
    // this is needed, cause by default DOMPurify doesnot includes target attr
    ADD_ATTR: ["target"],
  });
};
