// @flow strict
import * as React from "react";

export type TypeGDPRContext = {|
  isEU: boolean,
  hasEnabledTracking: boolean,
  countryCode: null | string,
|};

const GDPRContext: React$Context<TypeGDPRContext> = React.createContext({
  isEU: false,
  hasEnabledTracking: false,
  countryCode: null,
});

export default GDPRContext;
