// @flow

import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import Image from "./Image";

import {type CssSettings} from "../../types/types/cssSettings";
import {type ContentfulStatisticCardFragment} from "../../types/__generated__/ContentfulStatisticCardFragment";
import {BREAKPOINTS} from "../../styles/sizes";
import {DARK_GREY_2, NAVY, WHITE} from "../../styles/colors";
import {BOLD_EXTENDED_FONT_FAMILY, h1, p} from "../../styles/typography";

type Props = {|
  +card: ContentfulStatisticCardFragment,
  +decorations?: Array<CssSettings> | CssSettings | null,
|};

function StatisticCard(props: Props): React.Node {
  const {card, decorations} = props;

  const {icon, title, description} = card;

  return (
    <div className={css(styles.container, decorations)}>
      {icon && <Image image={icon} decorations={styles.icon} />}
      {title && <div className={css(styles.title)}>{title}</div>}
      {description && <p className={css(styles.description)}>{description}</p>}
    </div>
  );
}
export default StatisticCard;

const styles = StyleSheet.create({
  container: {
    position: "relative",
    boxSizing: "content-box",
    padding: "14px",
    backgroundColor: WHITE,
    borderRadius: "5px",
  },
  icon: {
    width: "32px",
    height: "auto",

    [BREAKPOINTS.TABLET_PORTRAIT]: {
      width: "44px",
      height: "auto",
    },
  },
  title: {
    fontFamily: BOLD_EXTENDED_FONT_FAMILY,
    fontSize: "28px",
    lineHeight: "33px",
    color: NAVY,
    marginBottom: "4px",

    [BREAKPOINTS.TABLET_PORTRAIT]: {
      fontSize: "42px",
      lineHeight: "52px",
    },
  },
  description: {
    ...p,
    color: DARK_GREY_2,
    fontSize: "14px",
  },
});
