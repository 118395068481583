// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {useIntl} from "gatsby-plugin-react-intl";

import {TEAL_4, WHITE} from "../styles/colors";

type Props = {
  isOpen: boolean,
  toggleIsOpen: boolean => void,
};

const MobileNavIcon = (props: Props): React.Node => {
  const intl = useIntl();
  const {toggleIsOpen, isOpen} = props;

  return (
    <button
      type="button"
      className={css(styles.mobileNavIcon)}
      onClick={() => {
        toggleIsOpen(!isOpen);
      }}
      aria-label={intl.formatMessage({id: "open_menu"})}
    >
      <div className={css(styles.iconContainer)}>
        <div className={css(styles.iconBar, isOpen && styles.iconClose)} />
        <div className={css(styles.iconBar, isOpen && styles.iconClose)} />
        <div className={css(styles.iconBar, isOpen && styles.iconClose)} />
      </div>
    </button>
  );
};

export default MobileNavIcon;

const styles = StyleSheet.create({
  mobileNavIcon: {
    background: "none",
    border: "none",
    outline: "none",
    padding: "0px",
    cursor: "pointer",
    padding: "0 8px",

    ":focus-visible": {
      outline: `3px solid ${TEAL_4}`,
      outlineOffset: "2px",
    },
  },

  iconContainer: {
    position: "relative",
  },

  iconBar: {
    width: "20px",
    height: "2px",
    backgroundColor: WHITE,
    marginBottom: "5px",
    transition: "transform 300ms ease-in-out, opacity 100ms ease-in-out",

    ":last-child": {
      marginBottom: 0,
    },
  },

  iconClose: {
    ":first-child": {
      transform: "translate(2px) rotate(45deg) scale(1.05)",
      transformOrigin: "top left",
    },

    ":nth-child(2)": {
      opacity: "0",
    },

    ":last-child": {
      transform: "translate(2px) rotate(-45deg) scale(1.05)",
      transformOrigin: "bottom left",
    },
  },
});
