// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {DARK_GREY_2, LIME_1, MIDNIGHT_BLUE} from "../../styles/colors";
import {BREAKPOINTS} from "../../styles/sizes";
import {eyebrow, h3, p} from "../../styles/typography";

import {type ContentfulPartnerCardFragment} from "../../types/__generated__/ContentfulPartnerCardFragment";

import Image from "./Image";
import MarkDown from "./MarkDown";

type Props = {|
  +card: ContentfulPartnerCardFragment,
|};

function PartnerCard(props: Props): React.Node {
  const {card} = props;
  const {headline, eyebrow, body, logo, image} = card;

  return (
    <div className={css(styles.wrapper)}>
      {image && (
        <Image
          image={image}
          objectFit={"cover"}
          decorations={styles.image}
          imageWrapperDecorations={styles.imageWrapper}
        />
      )}

      <div className={css(styles.contentWrapper)}>
        {logo && (
          <Image
            image={logo}
            objectFit={"contain"}
            objectPosition={"top left"}
            decorations={styles.logo}
          />
        )}
        {eyebrow && <p className={css(styles.eyebrow)}>{eyebrow}</p>}
        {headline && <h3 className={css(styles.headline)}>{headline}</h3>}
        {body && <MarkDown body={body?.body || ""} decorations={styles.body} />}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    background: LIME_1,
    borderRadius: "5px",
  },
  contentWrapper: {
    padding: "32px",
  },
  eyebrow: {
    ...eyebrow,
    color: DARK_GREY_2,
    marginBottom: "12px",
  },
  headline: {
    ...h3,
    marginBottom: "16px",
  },
  body: {
    ...p,
    color: MIDNIGHT_BLUE,
    fontSize: "16px",
  },
  imageWrapper: {
    width: "100%",

    [BREAKPOINTS.DESKTOP]: {
      display: "block",
    },
  },
  image: {
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    aspectRatio: "2 / 1",

    [BREAKPOINTS.DESKTOP]: {
      aspectRatio: "3 / 2",
    },
  },
  logo: {
    height: "40px",
    width: "auto",
    marginBottom: "32px",
  },
});

export default PartnerCard;
