// @flow strict

export const locationPath = (location: string, locale: string): string => {
  return locale === "de" ? location.replace("/de/", "/") : location;
};

export const onHomepage = (location: string): boolean => {
  return location === "/" || location === "/de" || location === "/de/";
};

export const getCurrentLocationPath = (): string => {
  const pathname =
    typeof window !== "undefined" ? window.location.pathname : "";
  return pathname;
};
