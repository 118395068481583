// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {useIntl} from "gatsby-plugin-react-intl";

import {type ContentfulCTAFragment} from "../../types/__generated__/ContentfulCTAFragment";

import {MEDIUM_FONT_FAMILY} from "../../styles/typography";

import {WHITE, BLACK, SILVER, SEAFOAM_5} from "../../styles/colors";
import {SIDE_MARGIN, SIDE_MARGIN_WIDE, BREAKPOINTS} from "../../styles/sizes";

type AnnouncementBannerProps = {|
  +headline?: string,
  +body?: string,
  +cta?: ContentfulCTAFragment,
|};

function AnnouncementBanner(props: AnnouncementBannerProps): React.Node {
  const intl = useIntl();
  const {headline, body, cta} = props;

  return (
    <div className={css(styles.superNavContainer)}>
      <div className={css(styles.superNavContent)}>
        <div className={css(styles.navLeft)}>
          <div className={css(styles.contentWrapper)}>
            <span>
              {headline != null && (
                <>
                  <span className={css(styles.contentHeadline)}>
                    {headline}
                  </span>
                </>
              )}
              {body != null && body}&nbsp;
            </span>

            {cta != null && (
              <a className={css(styles.promotionalCta)} href={cta.link}>
                {cta.label}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  superNavContainer: {
    position: "relative",
    zIndex: 13,
    borderBottom: `1px solid ${BLACK}`,
    backgroundColor: SEAFOAM_5,
  },
  superNavContainerPlaceholder: {
    visibility: "hidden",
    overflow: "hidden",
  },
  superNavContent: {
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
    justifyContent: "space-between",
    padding: `14px 20px`,
    [BREAKPOINTS.DESKTOP]: {
      padding: `14px ${SIDE_MARGIN}`,
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `14px ${SIDE_MARGIN_WIDE}`,
    },
  },
  navLeft: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
  },
  contentWrapper: {
    display: "inline-block",
    fontFamily: [MEDIUM_FONT_FAMILY],
    fontSize: "13px",
    lineHeight: "20px",
    color: WHITE,
  },
  contentHeadline: {
    textDecoration: "none",
  },
  promotionalCta: {
    paddingLeft: 4,
    color: WHITE,
    cursor: "pointer",
    textDecoration: "underline",
  },
  help: {
    color: SILVER,
    fontSize: "12px",
    fontFamily: [MEDIUM_FONT_FAMILY],
    textTransform: "capitalize",
  },
});

export default AnnouncementBanner;
