/**
 * @flow
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import {HelmetProvider} from "react-helmet-async";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import injectGlobalStyles from "aphrodite-globals";
import "@formatjs/intl-locale/polyfill";

import GDPRWrapper from "./src/components/gdpr/GDPRWrapper";
import ScrollToTop from "./src/components/ScrollToTop";
import {contactPageGlobalStyles} from "./src/components/ContactForm";
import {SEAFOAM_5} from "./src/styles/colors";

const twoColSplitContentGlobalStyles: mixed = {
  ".dangerouslySetContentStyle a": {
    color: SEAFOAM_5,
    textDecoration: "none",
    ":visited": {
      color: SEAFOAM_5,
    },
  },
};

export const wrapRootElement = ({
  element,
}: {
  element: React.Node,
}): React.Node => {
  return (
    <HelmetProvider>
      <GDPRWrapper>
        <ScrollToTop />
        {element}
      </GDPRWrapper>
    </HelmetProvider>
  );
};

export const onInitialClientRender = async () => {
  if (process.env.CONTEXT === "production") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.NODE_ENV,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
};

injectGlobalStyles({
  ...contactPageGlobalStyles,
  ...twoColSplitContentGlobalStyles,
});
