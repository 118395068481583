// @flow strict
export const MAX_MOBILE_WIDTH = "1250px";
export const MIN_WIDE_WIDTH = "2400px";
export const SIDE_MARGIN = "10%";
export const SIDE_MARGIN_WIDE = "20%";
export const SIDE_MARGIN_MOBILE = "25px";
export const HERO_MAX_HEIGHT = "660px";

export const BREAKPOINTS = {
  TABLET_PORTRAIT: "@media (min-width: 768px)",
  TABLET_LANDSCAPE: "@media (min-width: 1024px)",
  DESKTOP: "@media (min-width: 1250px)",
  DESKTOP_LARGE: "@media (min-width: 2400px)",
  HERO_BREAK_POINT: "@media (max-width: 1630px) and (min-width: 1250px)",
};
