// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {getSrc, getImage} from "gatsby-plugin-image";

import Video from "./Video";
import {
  MIDNIGHT_BLUE,
  SEAFOAM_5,
  TEXT_GRAY,
  WHITE,
  DARK_GREY_1,
  OFF_WHITE,
} from "../../styles/colors";
import {h2, p, MEDIUM_FONT_FAMILY} from "../../styles/typography";
import {
  BREAKPOINTS,
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
} from "../../styles/sizes";

import {type CTALinkType} from "../CTAButton.jsx";
import {type ContentfulCTAFragment} from "../../types/__generated__/ContentfulCTAFragment";
import {
  type ContentfulImageFixed,
  type ContentfulImageFluid,
} from "../../types/fragments/ContentfulImageFragment";
import {type ContentfulFastFactFragment} from "../../types/__generated__/ContentfulFastFactFragment";
import {type ContentfulMultiColumnFragment} from "../../types/__generated__/ContentfulMultiColumnFragment";
import {type ContentfulSplitModuleFragment_mediaCta} from "../../types/__generated__/ContentfulSplitModuleFragment";

import CTAButton from "../CTAButton";
import Image from "./Image.jsx";
import FastFact from "./FastFact";
import MarkDown from "./MarkDown";
import MultiColumn from "./MultiColumn";

type Props = {|
  headline?: string,
  body?: string,
  media?: ContentfulImageFixed | ContentfulImageFluid,
  contentDirection?: string,
  mediaCta?: ContentfulSplitModuleFragment_mediaCta,
  imgStyle?: string,
  cta?: ContentfulCTAFragment,
  playVideoInline?: boolean,
  darkmode?: boolean,
  footnote?: string,
  mediaContent?: ContentfulMultiColumnFragment | ContentfulFastFactFragment,
|};

function SplitModule(props: Props): React.Node {
  const {
    headline,
    body,
    media,
    contentDirection,
    mediaCta,
    imgStyle,
    darkmode,
    cta,
    footnote,
    mediaContent,
    playVideoInline,
  } = props;
  const isDarkMode = darkmode === true;
  const CTALinkType = cta?.openInNewWindow ? "external" : "local";
  const imageDirection = contentDirection === "left" ? "right" : "left";

  let mediaContentComponent = null;

  if (mediaContent && playVideoInline !== true) {
    switch (mediaContent.__typename) {
      case "ContentfulFastFact":
        mediaContentComponent = (
          <FastFact
            fastFact={mediaContent}
            containerClass={styles.fastFactContainer}
            headlineClass={styles.fastFactHeadline}
            bodyClass={styles.fastFactbody}
          />
        );
        break;
      case "ContentfulMultiColumn":
        mediaContentComponent = (
          <MultiColumn
            headline={mediaContent.headline}
            decorations={styles.multiColumn}
            cardWrapperDecorations={styles.multiColumnCards}
            cardDecorations={[styles.multiColumnCard]}
            showDividerBetweenCards={true}
            // $FlowFixMe
            cards={mediaContent.cards || []}
          />
        );
        break;
    }
  }

  const getMediaContent = () => {
    if (mediaCta?.link && media) {
      if (playVideoInline === true) {
        let thumbnail = "";

        if (media) {
          thumbnail = getSrc(getImage(media));
        }
        return (
          <Video
            videoSource={mediaCta?.link}
            videoThumbnail={thumbnail}
            videoTitle={mediaCta?.label}
            noPlayIcon={true}
          />
        );
      }

      return (
        <a
          href={mediaCta?.link}
          className={css(styles.visualImage)}
          target={mediaCta?.openInNewWindow && "blank"}
          rel={mediaCta?.openInNewWindow && "noreferrer noopener"}
          aria-label={mediaCta?.label}
        >
          <Image
            image={media}
            objectFit={"cover"}
            imageWrapperDecorations={styles.visualImageWrapperDecoration}
            decorations={styles.image}
          />
        </a>
      );
    } else if (!mediaCta?.link && media) {
      return (
        <div className={css(styles.visualImage, styles.backgroundGreen)}>
          <Image
            image={media}
            objectFit={"cover"}
            imageWrapperDecorations={styles.visualImageWrapperDecoration}
            decorations={styles.image}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={css(
        styles.section,
        imageDirection === "left" ? styles.imgLeft : null,
        isDarkMode && styles.sectionDark
      )}
    >
      <div
        className={css(
          styles.content,
          imageDirection === "left" ? styles.textRight : null
        )}
      >
        <h2 className={css(styles.title, isDarkMode && styles.titleDark)}>
          {headline}
        </h2>
        <MarkDown
          body={body || ""}
          decorations={
            isDarkMode ? [styles.body, styles.bodyDark] : styles.body
          }
          anchorStyles={styles.markdownAnchorStyle}
        />
        {cta && cta?.link ? (
          <div className={css(styles.CTA)}>
            <CTAButton
              text={cta.label || ""}
              link={cta.link || ""}
              linkType={CTALinkType}
              amplitudeCtaName={cta.amplitudeCtaName || ""}
            />
          </div>
        ) : null}
        {footnote && (
          <MarkDown
            body={footnote}
            decorations={styles.disclaimer}
            anchorStyles={styles.markdownAnchorStyle}
          />
        )}
      </div>
      <div
        className={css(
          styles.visual,
          playVideoInline && styles.visualInlinePlayer
        )}
      >
        {mediaContentComponent}
        {getMediaContent()}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  section: {
    background: OFF_WHITE,
    display: "flex",
    padding: `0px`,
    flexDirection: "column",
    [BREAKPOINTS.DESKTOP]: {
      flexDirection: "row",
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `0px ${SIDE_MARGIN_WIDE}`,
    },
  },
  imgLeft: {
    [BREAKPOINTS.DESKTOP]: {
      flexDirection: "row-reverse !important",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    width: "100%",
    padding: SIDE_MARGIN_MOBILE,
    marginBottom: "35px",
    [BREAKPOINTS.DESKTOP]: {
      width: "50%",
      padding: `0px 10% 0px ${SIDE_MARGIN}`,
      marginBottom: "0px",
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `0px`,
    },
  },
  textRight: {
    [BREAKPOINTS.DESKTOP]: {
      paddingLeft: "10%",
    },
  },
  disclaimer: {
    color: TEXT_GRAY,
    fontSize: "16px",
    marginTop: "32px",
  },
  title: {
    ...h2,
    color: MIDNIGHT_BLUE,
    margin: "0px 0px 25px 0px",
    maxWidth: "600px",
    marginTop: "20px",
    [BREAKPOINTS.DESKTOP]: {
      marginTop: "0px",
    },

    ":after": {
      display: "block",
      content: "''",
      width: "70px",
      height: "6px",
      backgroundColor: MIDNIGHT_BLUE,
      marginTop: "24px",
      marginBottom: "24px",
    },
  },

  titleDark: {
    color: WHITE,

    ":after": {
      backgroundColor: WHITE,
    },
  },
  body: {
    ...p,
    color: MIDNIGHT_BLUE,
    maxWidth: "600px",
  },
  bodyDark: {
    color: WHITE,
  },
  visual: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "25px 0px",
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    color: "transparent",
    width: "100%",
    height: "340px",
    [BREAKPOINTS.DESKTOP]: {
      width: "50%",
      height: "598px",
    },
  },
  visualInlinePlayer: {
    padding: "25px 0px 0px",
    height: "auto",

    [BREAKPOINTS.DESKTOP]: {
      padding: 0,
      width: "50%",
      height: "auto",
    },
  },
  visualImageWrapperDecoration: {
    height: "100%",
    width: "100%",
  },
  visualImage: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  image: {
    height: "100%",
    width: "100%",
  },
  CTA: {
    marginTop: "35px",
  },
  clickable: {
    cursor: "pointer",
  },
  sectionDark: {
    background: MIDNIGHT_BLUE,
    color: WHITE,
  },
  fastFactContainer: {
    zIndex: 1,
    background: WHITE,
    boxShadow: "rgb(0 0 0 / 20%) 0px 0px 15px 0px",
    padding: "25px",
    width: "75%",
    minWidth: "unset",
    maxWidth: "600px",
    [BREAKPOINTS.DESKTOP]: {
      maxWidth: "360px",
      width: "360px",
      padding: "45px",
    },
  },
  multiColumn: {
    zIndex: 1,
    padding: 0,
    maxWidth: "324px",
    background: "transparent",
    [`${BREAKPOINTS.DESKTOP}`]: {
      padding: 0,
      marginBottom: 0,
    },
    [`${BREAKPOINTS.DESKTOP_LARGE}`]: {
      padding: 0,
      marginBottom: 0,
    },
  },
  multiColumnCards: {
    [BREAKPOINTS.TABLET_PORTRAIT]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  multiColumnCard: {
    [BREAKPOINTS.TABLET_PORTRAIT]: {
      ":nth-child(2n):after": {
        display: "none",
      },
    },
  },
  fastFactHeadline: {
    color: SEAFOAM_5,
    fontFamily: [MEDIUM_FONT_FAMILY],
    fontSize: "32px",
    lineHeight: "40px",
    [BREAKPOINTS.DESKTOP]: {
      fontSize: "88px",
      lineHeight: "100px",
    },
  },
  fastFactbody: {
    color: DARK_GREY_1,
    fontSize: "16px",
    lineHeight: 1.2,
    paddingBottom: "0px",
    [BREAKPOINTS.DESKTOP]: {
      paddingBottom: "25px",
    },
  },
  markdownAnchorStyle: {
    color: SEAFOAM_5,
    textDecoration: "none",
  },
  backgroundGreen: {
    backgroundColor: SEAFOAM_5,
  },
});

export default SplitModule;
