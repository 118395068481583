// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {injectIntl, useIntl} from "gatsby-plugin-react-intl";

import GDPRContext from "../components/gdpr/GDPRContext";
import {REGULAR_FONT_FAMILY} from "../styles/typography";
import HeaderInfo from "../components/v2/HeaderInfo";
import ContentfulMenuNavigationWrapper from "../components/v2/ContentfulWrapper/ContentfulMenuNavigationWrapper";
import {type ContentfulHeaderInfoFragment} from "../types/__generated__/ContentfulHeaderInfoFragment";
import ContentfulFooterWrapper from "../components/v2/ContentfulWrapper/ContentfulFooterWrapper";

import "../styles/reset.css";

type Props = {|
  +children: React.ChildrenArray<React.Element<any> | null>,
  +headerInfo?: ContentfulHeaderInfoFragment,
  +showAnnouncementBanner?: boolean | null,
  +slug: string,
|};

// type LayoutData = {|
//   +site: {
//     +siteMetadata: {
//       +title: string,
//       +description: string,
//     },
//   },
// |};

function Layout(props: Props): React.Node {
  const {hasEnabledTracking} = React.useContext(GDPRContext);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = React.useState(false);
  const {children, showAnnouncementBanner, headerInfo, slug} = props;
  const intl = useIntl();

  const inertProps = {};
  if (mobileMenuIsOpen) {
    inertProps.inert = 1;
  }

  return (
    <div className={css(styles.app)}>
      <HeaderInfo
        slug={slug}
        title={headerInfo?.title || intl.formatMessage({id: "header_title"})}
        description={
          headerInfo?.description ||
          intl.formatMessage({id: "header_description"})
        }
        imageSrc={headerInfo?.image}
        noindexNofollow={headerInfo?.noindexNofollow}
      />
      <ContentfulMenuNavigationWrapper
        showAnnouncementBanner={showAnnouncementBanner}
        mobileMenuOpenCallback={setMobileMenuIsOpen}
      />
      <div id="flexport-org-main-content" {...inertProps}>
        {hasEnabledTracking && (
          <noscript>
            <iframe
              title="GetSiteControl"
              src="https://www.googletagmanager.com/ns.html?id=GTM-N47WM58"
              height="0"
              width="0"
              style={{display: "none", visibility: "hidden"}}
            />
          </noscript>
        )}
        {children}
      </div>
      <ContentfulFooterWrapper mobileMenuIsOpen={mobileMenuIsOpen} />
    </div>
  );
}

export default (injectIntl(Layout): any);

const styles = StyleSheet.create({
  app: {
    fontFamily: [REGULAR_FONT_FAMILY],
  },
});
