// @flow

import * as React from "react";
import {StyleSheet, css} from "aphrodite";

// source: https://github.com/cookpete/react-player/blob/master/src/patterns.js
const MATCH_URL_YOUTUBE =
  /(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\/|watch\?v=|watch\?.+&v=|shorts\/|live\/))((\w|-){11})|youtube\.com\/playlist\?list=|youtube\.com\/user\//;
const MATCH_URL_WISTIA =
  /(?:wistia\.(?:com|net)|wi\.st)\/(?:medias|embed)\/(?:iframe\/)?(.*)$/;

export const CanPlay = (src: ?string): boolean => {
  if (!src || !src.trim()) {
    return false;
  }

  if (MATCH_URL_YOUTUBE.test(src) || MATCH_URL_WISTIA.test(src)) {
    return true;
  }

  return false;
};

export const GetVideoType = (src: ?string): string | null => {
  if (!src || !CanPlay(src)) {
    return null;
  }

  if (MATCH_URL_YOUTUBE.test(src)) {
    return "youtube";
  }

  if (MATCH_URL_WISTIA.test(src)) {
    return "wistia";
  }

  return null;
};

export type CssSettings = {
  [string]: number | string | CssSettings,
};

// flowlint nonstrict-import:error
type Props = {|
  +videoSource?: ?string,
  +videoTitle?: ?string,
  +videoThumbnail?: ?string,
  +videoStyle?: CssSettings,
  +noPlayIcon?: boolean,
|};

// Currently we only support inline video player
// should we need to support other view mode, i.e. autoplay
// or play in modal, we can expand/refactor this component
export default function ({
  videoSource,
  videoTitle,
  videoThumbnail,
  videoStyle,
  noPlayIcon = false,
}: Props): React.Node {
  if (!CanPlay(videoSource)) {
    return null;
  }

  const thumbnailStyle = `
  * {
    padding:0;
    margin:0;
    overflow:hidden;
  }

  html,body {
    height:100%;
  }

  img,span {
    position:absolute;
    width:100%;
    top:0;
    bottom:0;
    margin:auto;
  }

  span {
    height:1.5em;
    text-align:center;
    font:48px/1.5 sans-serif;
    color:white;
    text-shadow:0 0 0.5em black;
  }
  `;

  const videoType = GetVideoType(videoSource);
  let frameSrc = "";

  if (videoSource) {
    switch (videoType) {
      case "youtube":
        const destructYoutubeUrl = videoSource.match(MATCH_URL_YOUTUBE);

        const videoId =
          destructYoutubeUrl && destructYoutubeUrl.length >= 2
            ? destructYoutubeUrl[1]
            : "";

        if (videoId) {
          frameSrc = `https://www.youtube.com/embed/${videoId}?autoplay=1`;
        }
        break;
      case "wistia":
        frameSrc = `${videoSource}?videoFoam=true&playerColor=ff0000`;
        break;
      default:
        break;
    }
  }

  let thumbnail = "";

  if (videoThumbnail && videoSource) {
    thumbnail = `<a href="${frameSrc}">
      <img src="${videoThumbnail}" alt="${videoTitle || ""}">${
      noPlayIcon === false ? "<span>▶</span>" : ""
    }
    </a>`;
  }

  return (
    <iframe
      className={css(styles.player, videoStyle)}
      src={frameSrc}
      srcdoc={`<style>${thumbnailStyle}</style>${thumbnail}`}
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
      allowfullscreen
      title={videoTitle}
      loading="lazy"
    ></iframe>
  );
}
const styles = StyleSheet.create({
  player: {
    aspectRatio: "16 / 9",
    height: "100%",
    width: "100%",
  },
});
