// @flow
import * as React from "react";

import Hero from "./Hero";
import {type CssSettings} from "../../types/types/cssSettings";
import SplitModule from "./SplitModule";
import MultiColumn from "./MultiColumn";
import Quote from "./Quote";
import LogoShowcase from "./LogoShowcase";
import AidTrackerMap from "./Map/AidTrackerMap";
import GetStartedModule from "./GetStartedModule";
import LazyLoadMultiColumn from "./LazyLoadMultiColumn";
import HeadlineFullBleedImageStack from "./HeadlineFullBleedImageStack";

type SectionProps = {|
  +sections?: any,
  +decorations?: Array<CssSettings | string> | CssSettings,
  +bodyRight?: React$Element<"div">,
|};
const Sections = ({sections = [], bodyRight}: SectionProps): React.Node => {
  const loadSection = (section: Object) => {
    switch (section.__typename) {
      case "ContentfulHero": {
        return (
          <Hero
            headline={section?.headline}
            headlineColor={section?.headlineColor}
            body={section?.body?.body}
            image={section.image}
            cta={section?.cta}
            background={section.background}
            bodyRight={bodyRight}
          />
        );
      }
      case "ContentfulSplitModule": {
        return (
          <SplitModule
            headline={section.headline}
            body={section?.body?.body}
            media={section.media}
            contentDirection={section.contentDirection}
            mediaCta={section?.mediaCta}
            cta={section.cta}
            darkmode={section.darkmode}
            footnote={section.footnote}
            mediaContent={section.mediaContent}
            playVideoInline={section.playVideoInline}
          />
        );
      }
      case "ContentfulQuote": {
        return (
          <Quote
            logo={section.logo}
            body={section.body?.body}
            authorName={section.authorName}
            authorRole={section.authorRole}
          />
        );
      }
      case "ContentfulMultiColumn": {
        return (
          <MultiColumn headline={section.headline} cards={section.cards} />
        );
      }
      case "ContentfulPartners": {
        return (
          <LogoShowcase
            headline={section.headline}
            darkmode={section.darkmode}
            logos={section.logos}
          />
        );
      }
      case "ContentfulAidTrackerMap": {
        return (
          <AidTrackerMap
            hideLegend={section.hideLegend}
            title={section.title}
            eyebrow={section.eyebrow}
            eyebrowIcon={section.eyebrowIcon}
            description={section.description}
            statistic={section.statistic}
            aidTrackerCampaigns={section.aidTrackerCampaigns}
            mapDefaultCenterPosition={section.mapDefaultCenterPosition}
            mapDefaultZoomLevel={section.mapDefaultZoomLevel}
            clusterRadius={section.clusterRadius}
          />
        );
      }
      case "ContentfulGetStartedModule": {
        return (
          <GetStartedModule
            headline={section.headline}
            description={section.description}
            image={section.image}
            cta={section.cta}
          />
        );
      }
      case "ContentfulLazyLoadMultiColumn": {
        return (
          <LazyLoadMultiColumn
            headline={section.headline}
            description={section.description}
            showMoreButtonLabel={section.showMoreButtonLabel}
            cards={section.lazyLoadCards}
          />
        );
      }
      case "ContentfulHeadlineFullBleedImageStack": {
        return (
          <HeadlineFullBleedImageStack
            headline={section.headline}
            description={section.description}
            desktopImage={section.desktopImage}
            mobileImage={section.mobileImage}
          />
        );
      }
      default:
        return null;
    }
  };
  return (
    <>
      {sections?.map((section, index) => {
        return <div key={index}>{loadSection(section)}</div>;
      })}
    </>
  );
};
export default Sections;
