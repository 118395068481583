// @flow
import * as React from "react";
import {useState, useEffect, useCallback} from "react";
import TrackingTags from "./TrackingTags";

import GDPRContext from "./GDPRContext";

const IS_PROD = process.env.GATSBY_SERVER_ENV === "prod";

type Props = {
  children?: React.Node,
};

const GDPRWrapper = (props: Props): React.Node => {
  const {children} = props;

  const [trackingEnabled, setTrackingEnabled] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [isEU, setIsEU] = useState(false);

  const getRequestLocationData = useCallback(async () => {
    const baseUrl = window?.location?.origin;
    const netlifyFunctionURL = baseUrl
      ? `${baseUrl}/.netlify/functions`
      : ".netlify/functions";
    const response = await fetch(`${netlifyFunctionURL}/country-code`);
    const data = await response.json();
    setCountryCode(data.countryCode);
    setTrackingEnabled(true);
    setIsEU(data.isEU);
  }, []);

  useEffect(() => {
    getRequestLocationData();
  }, [getRequestLocationData]);

  const GDPRState = {
    isEU,
    countryCode,
    hasEnabledTracking: trackingEnabled,
  };

  return (
    <GDPRContext.Provider value={GDPRState}>
      {children}
      {IS_PROD && <TrackingTags />}
    </GDPRContext.Provider>
  );
};

export default GDPRWrapper;
