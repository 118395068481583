// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {RED, WHITE, LIME_4, MIDNIGHT_BLUE} from "../styles/colors";

type Props = {
  text: string,
  componentStyle?: string,
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void,
};

function Button(props: Props): React.Node {
  const {onClick, text, componentStyle} = props;

  const buttonStyle = componentStyle === "green" ? styles.green : null;

  return (
    <button
      type="button"
      className={css(styles.button, buttonStyle)}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

const styles = StyleSheet.create({
  button: {
    display: "inline-block",
    backgroundColor: RED,
    color: WHITE,
    padding: "10px 15px",
    borderRadius: "5px",
    textDecoration: "none",
    fontSize: "16px",
    border: "none",
    cursor: "pointer",
    boxShadow: "0px 5px 7px 0px rgb(0 0 20 / 20%)",
    transition: "background-color 0.2s, box-shadow 0.2s, margin 0.2s",
    ":hover": {
      boxShadow: "0px 2px 7px 0px rgb(0 0 20 / 20%)",
      margin: "1px 0px -1px 0px",
    },
  },
  green: {
    background: LIME_4,
    color: MIDNIGHT_BLUE,
  },
});

export default Button;
