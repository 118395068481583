// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import {Link} from "gatsby";
import {useState} from "react";

import {BREAKPOINTS} from "../styles/sizes";
import {WHITE, MIDNIGHT_BLUE, TEXT_WHITE} from "../styles/colors";

type Props = {
  label: string,
  navItems: any,
  localeSlug: string,
  mobileMenuIsOpen: boolean,
  toggleIsOpen: boolean => void,
};

const NavTab = (props: Props): React.Node => {
  const [expanded, setExpanded] = useState(false);
  const {label, navItems, localeSlug, mobileMenuIsOpen, toggleIsOpen} = props;

  const onExpandToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={css(
        styles.navTabContainer,
        mobileMenuIsOpen && styles.mobileNavTabContainer
      )}
      onMouseEnter={!mobileMenuIsOpen ? onExpandToggle : null}
      onMouseLeave={!mobileMenuIsOpen ? onExpandToggle : null}
      onClick={onExpandToggle}
    >
      <div
        className={css(styles.navTab, mobileMenuIsOpen && styles.mobileNavTab)}
      >
        <button type="button" className={css(styles.navTabButton)}>
          {label}
        </button>
        <i className={css(styles.arrowDown)}></i>
      </div>
      {expanded && (
        <div className={css(!mobileMenuIsOpen && styles.navItemMenu)}>
          {navItems.map(navItem => (
            <li
              className={css(styles.menuItemList)}
              key={`nav-menu-item-${navItem.slug}`}
            >
              <Link
                onClick={() => toggleIsOpen(false)}
                className={css(
                  styles.menuItem,
                  mobileMenuIsOpen && styles.mobileMenuItem
                )}
                to={`${localeSlug}/${navItem.slug}`}
              >
                {navItem.navigationName}
              </Link>
            </li>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavTab;

const styles = StyleSheet.create({
  navTabContainer: {
    display: "block",
    margin: "0px 10px",
    backgroundColor: MIDNIGHT_BLUE,
    color: WHITE,
    textDecoration: "none",
    ":hover": {
      color: TEXT_WHITE,
    },
    margin: "0 10px",
    ":first-of-type": {
      marginTop: "0",
    },
    ":last-of-type": {
      marginBottom: "0",
    },
  },

  mobileNavTabContainer: {
    margin: "35px 0px",
    ":first-of-type": {
      marginTop: "25px",
    },
    ":last-of-type": {
      marginBottom: "10px",
    },
  },

  navTab: {
    position: "relative",
    cursor: "pointer",
    textAlign: "center",
  },

  mobileNavTab: {
    textAlign: "left",
  },

  navTabButton: {
    paddingLeft: "0px",
    paddingRight: "15px",
    color: WHITE,
    cursor: "pointer",

    ":hover": {
      color: TEXT_WHITE,
    },

    [BREAKPOINTS.DESKTOP]: {
      paddingLeft: "25px",
      paddingRight: "0px",
    },
  },

  navItemMenu: {
    position: "absolute",
    backgroundColor: MIDNIGHT_BLUE,
    minWidth: "150px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingBottom: "10px",
  },

  menuItemList: {
    margin: 0,
  },

  menuItem: {
    display: "block",
    margin: "0px 10px",
    color: WHITE,
    textDecoration: "none",
    ":hover": {
      color: TEXT_WHITE,
    },
    paddingLeft: "10px",
    paddingRight: "10px",
    margin: "35px 0px",
    ":first-of-type": {
      marginTop: "25px",
    },
    ":last-of-type": {
      marginBottom: "10px",
    },
  },

  mobileMenuItem: {
    paddingLeft: "30px",
  },

  arrowDown: {
    display: "inline-block",
    marginTop: ".7em",
    borderColor: "transparent",
    borderTopColor: WHITE,
    borderStyle: "solid",
    borderWidth: ".5em .38em 0",
    transform: "matrix(-1, 0, 0, 1, 0, 0)",

    [BREAKPOINTS.DESKTOP]: {
      float: "right",
      marginLeft: "16px",
    },
  },
});
