// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {DARK_GREY_2, LIGHT_GREY_1} from "../../../styles/colors";
import {
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
  BREAKPOINTS,
} from "../../../styles/sizes";
import {eyebrow, h1, p} from "../../../styles/typography";

import MultiColumn from "../MultiColumn";

import {
  type ContentfulImageFixed,
  type ContentfulImageFluid,
} from "../../../types/fragments/ContentfulImageFragment";

import {type ContentfulMultiColumnFragment} from "../../../types/__generated__/ContentfulMultiColumnFragment";

import {type ContentfulAidTrackerCampaignFragment} from "../../../types/__generated__/ContentfulAidTrackerCampaignFragment";
import Image from "../Image";

type Props = {|
  title?: ?string,
  eyebrow?: ?string,
  eyebrowIcon?: ContentfulImageFixed | ContentfulImageFluid,
  description?: ?string,
  statistic?: ContentfulMultiColumnFragment,
|};

function MapHeader(props: Props): React.Node {
  const {title, eyebrow, eyebrowIcon, description, statistic} = props;

  const hasHeader =
    title != null ||
    eyebrow != null ||
    eyebrowIcon != null ||
    description != null ||
    statistic != null;

  return hasHeader ? (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.titleWrapper)}>
        {eyebrow && (
          <div className={css(styles.eyebrowWrapper)}>
            {eyebrowIcon && (
              <Image decorations={styles.eyebrowIcon} image={eyebrowIcon} />
            )}
            <p className={css(styles.eyebrow)}>{eyebrow}</p>
          </div>
        )}
        {title && <h2 className={css(styles.title)}>{title}</h2>}
        {description && (
          <p className={css(styles.description)}>{description}</p>
        )}
      </div>
      {statistic != null && statistic.cards != null && (
        <MultiColumn
          decorations={styles.statistic}
          // $FlowFixMe
          cards={statistic.cards || []}
          showDividerBetweenCards={true}
        />
      )}
    </div>
  ) : null;
}

const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "column",

    background: LIGHT_GREY_1,

    padding: `45px ${SIDE_MARGIN_MOBILE}`,
    [BREAKPOINTS.DESKTOP]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: `45px ${SIDE_MARGIN}`,
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `45px ${SIDE_MARGIN_WIDE}`,
    },
  },
  eyebrowWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  eyebrowIcon: {
    width: "28px",
    height: "28px",

    [BREAKPOINTS.DESKTOP]: {
      width: "40px",
      height: "40px",
    },
  },
  eyebrow: {
    ...eyebrow,
    marginLeft: "16px",
  },
  titleWrapper: {
    [BREAKPOINTS.DESKTOP]: {
      flexBasis: "40%",
    },
  },
  title: {
    ...h1,

    fontSize: "28px",
    lineHeight: "33px",

    marginTop: "24px",

    [BREAKPOINTS.DESKTOP]: {
      fontSize: "30px",
      lineHeight: "40px",
    },
  },
  description: {
    ...p,
    color: DARK_GREY_2,
    fontSize: "16px",
  },
  statistic: {
    padding: 0,
    background: LIGHT_GREY_1,
    maxWidth: "324px",
    marginTop: "24px",

    [BREAKPOINTS.TABLET_PORTRAIT]: {
      maxWidth: "648px",
    },

    [`${BREAKPOINTS.DESKTOP}`]: {
      marginLeft: "24px",
      marginTop: 0,
      padding: 0,
    },
    [`${BREAKPOINTS.DESKTOP_LARGE}`]: {
      marginTop: 0,
      padding: 0,
    },
  },
});

export default MapHeader;
