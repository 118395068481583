exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-form-success-jsx": () => import("./../../../src/pages/contact-form-success.jsx" /* webpackChunkName: "component---src-pages-contact-form-success-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-templates-page-aid-tracker-campaign-jsx": () => import("./../../../src/templates/PageAidTrackerCampaign.jsx" /* webpackChunkName: "component---src-templates-page-aid-tracker-campaign-jsx" */),
  "component---src-templates-page-aid-tracker-jsx": () => import("./../../../src/templates/PageAidTracker.jsx" /* webpackChunkName: "component---src-templates-page-aid-tracker-jsx" */),
  "component---src-templates-page-campaign-jsx": () => import("./../../../src/templates/PageCampaign.jsx" /* webpackChunkName: "component---src-templates-page-campaign-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-page-with-stripe-jsx": () => import("./../../../src/templates/PageWithStripe.jsx" /* webpackChunkName: "component---src-templates-page-with-stripe-jsx" */)
}

