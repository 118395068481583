// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {
  MIDNIGHT_BLUE,
  SEAFOAM_5,
  TEXT_GRAY,
  WHITE,
  DARK_GREY_1,
  OFF_WHITE,
  LIGHT_GREY_1,
} from "../../styles/colors";
import {h2, p, MEDIUM_FONT_FAMILY} from "../../styles/typography";
import {
  BREAKPOINTS,
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
} from "../../styles/sizes";
import Image from "./Image";

import {
  type ContentfulImageFixed,
  type ContentfulImageFluid,
} from "../../types/fragments/ContentfulImageFragment";

type Props = {|
  headline?: ?string,
  description?: ?string,
  desktopImage: ContentfulImageFixed | ContentfulImageFluid,
  mobileImage?: ContentfulImageFixed | ContentfulImageFluid,
|};

function HeadlineFullBleedImageStack(props: Props): React.Node {
  const {headline, description, desktopImage, mobileImage} = props;

  return (
    <section className={css(styles.section)}>
      <div className={css(styles.headlineWrapper)}>
        <h2 className={css(styles.headline)}>{headline}</h2>
        <p className={css(styles.description)}>{description}</p>
      </div>
      <div className={css(styles.image)}>
        <Image
          objectFit="contain"
          image={desktopImage}
          mobileImage={mobileImage}
        />
      </div>
    </section>
  );
}

const styles = StyleSheet.create({
  section: {
    background: OFF_WHITE,
    paddingTop: "30px",
    paddingBottom: "42px",
    [BREAKPOINTS.DESKTOP]: {
      paddingTop: "48px",
      paddingBottom: "28px",
    },
  },
  headlineWrapper: {
    paddingLeft: SIDE_MARGIN_MOBILE,
    paddingRight: SIDE_MARGIN_MOBILE,
    marginBottom: "20px",

    [BREAKPOINTS.DESKTOP]: {
      paddingLeft: SIDE_MARGIN,
      paddingRight: SIDE_MARGIN,
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      paddingLeft: SIDE_MARGIN_WIDE,
      paddingRight: SIDE_MARGIN_WIDE,
    },
  },
  headline: {
    ...h2,
    color: MIDNIGHT_BLUE,
    margin: "0px 0px 25px 0px",
    maxWidth: "600px",
    marginTop: "0",
    [BREAKPOINTS.DESKTOP]: {
      marginTop: "0px",
    },
  },
  description: {
    ...p,
    color: MIDNIGHT_BLUE,
    maxWidth: "600px",
  },
  image: {
    width: "100%",
    background: OFF_WHITE,
  },
});

export default HeadlineFullBleedImageStack;
