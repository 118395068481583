// @flow
export const BLACK = "#000";
export const WHITE = "#FFF";

export const TEXT_BLACK = "rgba(74,74,74, 0.85)";
export const TEXT_WHITE = "rgba(255,255,255, 0.85)";
export const TEXT_WHITE_LIGHT = "rgba(255,255,255, 0.5)";
export const TEXT_GRAY = "#9DA7B9";
export const TEXT_PLACEHOLDER = "rgb(140, 154, 171, 0.5)";

export const GRAY = "#8C9AAB";
export const MID_GREY = "#CCD1DA";
export const LIGHT_GREY = "#E7E7E7";
export const LIGHT_GREY_1 = "#E9EEF4";
export const LIGHT_GREY_2 = "#B8C1CB";
export const VERY_LIGHT_GREY = "#F6F9FC";
export const DARK_GREY_1 = "#4D5563";
export const DARK_GREY_2 = "#6B747E";

export const AQUA = "#45DABE";
export const MIDNIGHT_BLUE = "#052439";
export const SEAFOAM_2 = "#B1F5E8";
export const SEAFOAM_3 = "#7DEED8";
export const SEAFOAM_5 = "#319A86";
export const SEAFOAM_6 = "#1D5A4F";
export const OFF_WHITE = "#F4F2EF";
export const RED = "#FA5959";
export const YELLOW = "#FACF68";

export const TEAL_4 = "#4397AB";
export const CYANBLUE = "#344153";
export const SILVER = "#C4C4C4";
export const SOLITUDE = "#EBF0F6";
export const NAVY = "#24303E";
export const LIME_1 = "#F6FDDF";
export const LIME_2 = "#E5F8A0";
export const LIME_4 = "#B7E21F";

export const ASH = "#6B747E";
