/* eslint-disable camelcase */
// @flow

import React from "react";
import {css, StyleSheet} from "aphrodite";
import {GatsbyImage, getImage, withArtDirection} from "gatsby-plugin-image";

import {
  type ContentfulImageFixed,
  type ContentfulImageFluid,
} from "../../types/fragments/ContentfulImageFragment";

import {type CssSettings} from "../../types/types/cssSettings";
import Page from "../../templates/Page.jsx";
import {TEXT_WHITE} from "../../styles/colors";

export type Props = {|
  +image: ContentfulImageFixed | ContentfulImageFluid,
  +mobileImage?: ContentfulImageFixed | ContentfulImageFluid,
  +decorations?: Array<CssSettings> | CssSettings,
  +imageWrapperDecorations?: Array<CssSettings> | CssSettings,
  +objectFit?: string,
  +objectPosition?: string,
|};

// Render inline SVG with fallback non-svg images
export default function ({
  image,
  mobileImage,
  decorations,
  imageWrapperDecorations,
  objectFit = "cover",
  objectPosition,
}: Props): React$Element<*> {
  const alt = (() => {
    if (image?.description) {
      return image.description;
    }

    if (image?.title) {
      return image.title;
    }

    return "flexport-image";
  })();

  if (image?.svg && image?.file?.contentType === "image/svg+xml") {
    const {svg, file} = image;

    if (svg.content !== null) {
      // Inline SVGs
      return (
        <div
          className={css(decorations)}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{__html: svg.content}}
        />
      );
    }

    // SVGs that can/should not be inline
    return <img className={css(decorations)} src={file.url} alt={alt} />;
  }

  // if there is mobile image,
  // we need to pass it along to gatsby image component
  // GROW-3656
  // https://www.gatsbyjs.com/plugins/gatsby-image/#art-directing-multiple-images

  let sources = getImage(image);

  if (mobileImage) {
    const images = [
      {
        media: "(max-width: auto)",
        image: getImage(image),
      },
      {
        media: "(max-width: 1024px)",
        image: getImage(mobileImage),
      },
    ];

    sources = withArtDirection(getImage(image), images);
  }

  return (
    <div className={css(styles.imageWrapper, imageWrapperDecorations)}>
      <GatsbyImage
        className={css(decorations)}
        image={sources}
        objectFit={objectFit}
        objectPosition={objectPosition}
        alt={alt}
      />
      {image?.description && (
        <p className={css(styles.footNote)}>{image?.description}</p>
      )}
    </div>
  );
}
const styles = StyleSheet.create({
  imageWrapper: {
    position: "relative",
  },
  footNote: {
    position: "absolute",
    bottom: "8px",
    right: "15px",
    color: TEXT_WHITE,
    fontSize: "10px",
    textShadow: "0px 0px 5px #000",
    margin: "0px",
    maxHeight: "600px",
  },
});
