// @flow
import * as React from "react";
import {marked} from "marked";
import {css, StyleSheet} from "aphrodite";

import {sanitizeDom} from "../../utils/domSanitize";
import {WHITE} from "../../styles/colors";
import {type CssSettings} from "../../types/types/cssSettings";

type MarkDownProps = {|
  +decorations?: Array<CssSettings> | CssSettings,
  +body: string,
  +anchorStyles?: Array<CssSettings> | CssSettings,
  +listStyles?: Array<CssSettings> | CssSettings,
  +listItemStyles?: Array<CssSettings> | CssSettings,
|};

marked.setOptions({sanitize: false});
const renderer = new marked.Renderer();

const sanitizationOptions = {
  allowedTags: ["img", "h1", "h2", "h3", "h4", "h5", "h6", "span", "iframe"],
  allowedAttributes: {
    "*": ["class"],
    a: ["data-*", "href", "target"],
    img: ["src"],
    iframe: ["src", "width", "height", "style"],
  },
};

const MarkDown = ({
  body,
  decorations,
  anchorStyles,
  listStyles,
  listItemStyles,
}: MarkDownProps): React.Node => {
  /**
   *
   * @summary elements defination are kept inside components so that we can add
   * aditional styles froms props if required
   */
  renderer.link = (href: string, title: string, text: string) => {
    return `<a href="${href}" target="_blank" class="${css(
      styles.anchorStyle,
      anchorStyles ?? null
    )}">${text}</a>`;
  };
  renderer.list = (body: string, ordered: boolean) => {
    return ordered
      ? `<ol class="${css(
          styles.list,
          styles.ol,
          listStyles && listStyles
        )}">${body}</ol>`
      : `<ul class="${css(
          styles.list,
          listStyles && listStyles
        )}">${body}</ul>`;
  };

  renderer.listitem = (text: string) =>
    `<li class="${css(
      styles.listItem,
      listItemStyles && listItemStyles
    )}">${text}</li>`;

  return (
    <div
      className={css(decorations)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitizeDom(marked(body, {renderer}), sanitizationOptions),
      }}
    />
  );
};
const styles = StyleSheet.create({
  anchorStyle: {
    color: WHITE,
    textDecoration: "underline",
  },
  list: {
    marginTop: "18px",
    marginBottom: "18px",
  },
  listItem: {
    marginBottom: "0px",
  },
});
export default MarkDown;
