// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";
import type {ContentfulFastFactFragment} from "../../types/__generated__/ContentfulFastFactFragment";
import {TEXT_WHITE_LIGHT, WHITE, YELLOW} from "../../styles/colors";
import {BREAKPOINTS} from "../../styles/sizes";
import {MEDIUM_FONT_FAMILY, p} from "../../styles/typography";
import type {CssSettings} from "../../types/types/cssSettings";
import MarkDown from "./MarkDown";
import {parseColor} from "../../utils/helper-fuctions";

type Props = {
  fastFact: ContentfulFastFactFragment,
  containerClass?: Array<CssSettings> | CssSettings,
  headlineClass?: Array<CssSettings> | CssSettings,
  bodyClass?: Array<CssSettings> | CssSettings,
};

const FastFact = (props: Props): React.Node => {
  const {
    fastFact: {headline, headlineColor, body, footnote, eyebrow},
    containerClass,
    headlineClass,
    bodyClass,
  } = props;
  const containerStyle = containerClass || styles.container;
  const bodyStyle = bodyClass || styles.body;
  const headlineStyle = headlineClass || styles.headline;
  const headlineColorStyle = StyleSheet.create({
    headlineStyle: {
      color: headlineColor ? parseColor(headlineColor) : YELLOW,
    },
  });

  return (
    <div className={css(styles.container, containerStyle)}>
      <div className={css(styles.eyebrow, headlineColorStyle.headlineStyle)}>
        {eyebrow}
      </div>
      <div className={css(headlineStyle, headlineColorStyle.headlineStyle)}>
        {headline}
      </div>
      <MarkDown body={body?.body || ""} decorations={bodyStyle} />
      {footnote && <div className={css(styles.footnote)}>{footnote}</div>}
    </div>
  );
};
export default FastFact;

const styles = StyleSheet.create({
  container: {
    minWidth: "100%",
    [`${BREAKPOINTS.DESKTOP}`]: {
      width: "unset",
      maxWidth: "33%",
      minWidth: "unset",
      flex: "1 1 0",
    },
  },
  headline: {
    margin: "0px 0px 15px 0px",
    fontSize: "72px",
    fontWeight: "900",
    fontFamily: [MEDIUM_FONT_FAMILY],
    lineHeight: "80px",
    color: YELLOW,
  },
  body: {
    ...p,
    color: WHITE,
    minHeight: "48px",
  },
  footnote: {
    marginTop: "25px",
    color: TEXT_WHITE_LIGHT,
    fontSize: "10px",
  },
  eyebrow: {
    fontSize: "18px",
    height: "25px",
    marginBottom: "-10px",
    fontFamily: [MEDIUM_FONT_FAMILY],
    fontWeight: "900",
  },
});
