// @flow

export const parseColor = (color: string): string => {
  if (!color) return "";
  const start = color.indexOf("(") + 1;
  const end = color.indexOf(")");
  return color.substring(start, end);
};

export const dateFormat = (date: Date): string => {
  return new Date(date).toLocaleDateString("en-US");
};

export const debounce = (
  func: () => void,
  wait: number,
  immediate: boolean = false
): (() => void) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
