// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import {
  AQUA,
  MIDNIGHT_BLUE,
  SEAFOAM_5,
  WHITE,
  YELLOW,
} from "../../styles/colors";
import {h1, p} from "../../styles/typography";
import {
  BREAKPOINTS,
  HERO_MAX_HEIGHT,
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
} from "../../styles/sizes";
import CTAButton from "../CTAButton";
import MarkDown from "./MarkDown.jsx";
import Image from "./Image.jsx";

import {parseColor} from "../../utils/helper-fuctions";
import {
  type ContentfulImageFixed,
  type ContentfulImageFluid,
} from "../../types/fragments/ContentfulImageFragment";
import {type ContentfulCTAFragment} from "../../types/__generated__/ContentfulCTAFragment";

type Props = {
  headline: string,
  headlineColor?: string,
  body: string,
  image: ContentfulImageFixed | ContentfulImageFluid,
  cta?: ContentfulCTAFragment,
  bodyRight?: React.Node,
  background: ContentfulImageFixed | ContentfulImageFluid,
};

function Hero({
  headline,
  headlineColor,
  body,
  image,
  cta,
  bodyRight,
  background,
}: Props): React.Node {
  const headlineColorStyle = StyleSheet.create({
    headlineStyle: {
      color: headlineColor ? parseColor(headlineColor) : SEAFOAM_5,
    },
  });

  return (
    <div
      className={css(
        styles.hero,
        background ? styles.wide : null,
        bodyRight ? styles.body : null
      )}
    >
      {background && (
        <div className={css(styles.backgroundImageContainer)}>
          <Image
            image={background}
            objectFit={"contain"}
            decorations={styles.backGroundImage}
            imageWrapperDecorations={styles.backgroundImageWrapperDecorations}
          />
        </div>
      )}
      <div className={css(styles.content)}>
        <h2 className={css(styles.headline, headlineColorStyle.headlineStyle)}>
          {headline}
        </h2>
        <MarkDown decorations={styles.headingDescription} body={body} />
        {cta && cta.label && cta.link ? (
          <div className={css(styles.CTA)}>
            <CTAButton
              text={cta?.label || ""}
              link={cta?.link || ""}
              size={"large"}
              amplitudeCtaName={cta?.amplitudeCtaName || ""}
            />
          </div>
        ) : null}
      </div>
      {bodyRight && <div className={css(styles.bodyRight)}>{bodyRight}</div>}
      {!background && (
        <div className={css(styles.visual)}>
          <Image
            image={image}
            decorations={styles.coverImage}
            objectFit={"contain"}
            objectPosition={"bottom right"}
          />
        </div>
      )}
    </div>
  );
}

export default Hero;

const styles = StyleSheet.create({
  hero: {
    background: MIDNIGHT_BLUE,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: `0px`,
    maxHeight: "unset",
    [`${BREAKPOINTS.TABLET_LANDSCAPE}`]: {
      flexDirection: "row",
    },
    [`${BREAKPOINTS.DESKTOP}`]: {
      padding: `0px 0px 0px ${SIDE_MARGIN}`,
      maxHeight: HERO_MAX_HEIGHT,
    },
    [`${BREAKPOINTS.DESKTOP_LARGE}`]: {
      padding: `0px 0px 0px ${SIDE_MARGIN_WIDE}`,
    },
  },
  wide: {
    paddingTop: "20px",
    paddingBottom: "20px",
    position: "relative",
    background: "trasperent",
  },
  backgroundImageContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: "100vw",
    zIndex: -1,
  },
  backgroundImageWrapperDecorations: {
    width: "100%",
    height: "100%",
    [`${BREAKPOINTS.DESKTOP}`]: {
      width: "unset",
      height: "unset",
    },
  },
  backGroundImage: {
    width: "100%",
    height: "100%",
    [`${BREAKPOINTS.DESKTOP}`]: {
      height: "unset",
    },
  },
  body: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "0px",
    [`${BREAKPOINTS.DESKTOP}`]: {
      flexDirection: "row",
      maxHeight: "unset",
    },
    [`${BREAKPOINTS.DESKTOP_LARGE}`]: {
      padding: `0px 0px 0px ${SIDE_MARGIN_WIDE}`,
    },
  },
  bodyRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [`${BREAKPOINTS.DESKTOP}`]: {
      width: "55%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    width: "100%",
    padding: SIDE_MARGIN_MOBILE,
    paddingBottom: "45px",
    paddingRight: "32px",
    [`${BREAKPOINTS.HERO_BREAK_POINT}`]: {
      width: "40% !important",
    },
    [`${BREAKPOINTS.DESKTOP}`]: {
      padding: "unset",
      paddingBottom: "48px",
      paddingTop: "48px",
      width: "30%",
    },
  },
  headline: {
    ...h1,
    color: SEAFOAM_5,
    margin: "0px 0px 20px",
    marginTop: "20px",
    [`${BREAKPOINTS.DESKTOP}`]: {
      marginTop: "0px",
    },
  },
  headingDescription: {
    ...p,
    color: WHITE,
    margin: "0px",
  },
  CTA: {
    marginTop: "35px",
    marginBottom: "10px",
    [`${BREAKPOINTS.DESKTOP}`]: {
      marginBottom: "unset",
    },
  },
  visual: {
    display: "block",
    marginTop: "auto",
    width: "100% !important",

    [`${BREAKPOINTS.TABLET_LANDSCAPE}`]: {
      width: "70% !important",
      marginTop: "auto",
      display: "flex",
      justifyContent: "flex-end",
    },
    [`${BREAKPOINTS.HERO_BREAK_POINT}`]: {
      width: "60% !important",
    },
    [`${BREAKPOINTS.DESKTOP}`]: {
      display: "block",
      width: "70%",
      marginTop: "auto",
      display: "flex",
      justifyContent: "flex-end",
      maxHeight: "600px",
    },
  },
  coverImage: {
    display: "block",
    height: "340px",

    [BREAKPOINTS.TABLET_PORTRAIT]: {
      height: "100%",
    },
  },
  yellow: {
    color: YELLOW,
  },
  aqua: {
    color: AQUA,
  },
});
