// @flow
import * as React from "react";
import {Helmet} from "react-helmet-async";
import {useIntl} from "gatsby-plugin-react-intl";
import {getSrc, getImage} from "gatsby-plugin-image";
import favicon from "../../assets/favicon.png";
import useSiteMetadata from "../../hooks/useSiteMetadata";
import {
  type ContentfulImageFluid,
  type ContentfulImageFixed,
} from "../../types/fragments/ContentfulImageFragment";

type HeaderInfoProps = {|
  +slug: ?string,
  +title: ?string,
  +description: ?string,
  +imageSrc?: ?ContentfulImageFixed | ?ContentfulImageFluid,
  +noindexNofollow?: ?boolean,
|};

const HeaderInfo = (props: HeaderInfoProps): React.Node => {
  const {
    title,
    description,
    imageSrc,
    noindexNofollow = false,
    slug = "/",
  } = props;
  const intl = useIntl();
  const locale = intl.locale || intl.defaultLocale;

  const noIndexing = noindexNofollow === true;

  let ogImgSrc;
  let ogImgWidth;
  let ogImgHeight;
  let ogImgTitle;

  if (imageSrc) {
    const temp = getImage(imageSrc);

    ogImgWidth = temp.width;
    ogImgHeight = temp.height;
    ogImgTitle = imageSrc.title;

    ogImgSrc = getSrc(imageSrc);
    if (!ogImgSrc.includes("https")) {
      ogImgSrc = `https:${ogImgSrc}`;
    }
  } else {
    // default
    ogImgSrc =
      "https://images.ctfassets.net/c0y5galt2xob/FgHDIjWfKSYMIXouKl19k/2662329beee61ad6c0627f733dfcf8ab/Home_flexport_platform.jpg?w=2295&h=1722&q=80&fm=webp";
  }

  const {siteUrl} = useSiteMetadata();

  const path = !slug || slug === "/" ? "" : slug;
  const effectiveCanonicalSiteUrl =
    locale !== "en-US" ? `${siteUrl}/${locale}/` : siteUrl;
  return (
    <Helmet htmlAttributes={{lang: locale}}>
      <link rel="icon" type="image/png" href={favicon} />

      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="theme-color" content="#24303e" />

      {/* pre-connect third party lib */}
      <link rel="preconnect" href="https://images.ctfassets.net" />
      <link rel="preconnect" href="https://m.stripe.com" />
      <link rel="preconnect" href="https://js.stripe.com/v3" />

      <link rel="canonical" href={effectiveCanonicalSiteUrl} />

      <meta name="referrer" content="no-referrer" />

      {/* SEO localised page */}
      <link rel="alternate" href={`${siteUrl}/${path}`} hreflang="en-US" />
      <link rel="alternate" href={`${siteUrl}/de/${path}`} hreflang="de-DE" />
      <link rel="alternate" href={`${siteUrl}/${path}`} hreflang="x-default" />

      {description && <meta name="description" content={description} />}

      {/* disable indexing if needed */}
      {noIndexing && <meta name="robots" content="noindex, follow" />}

      {/* Open Graph tags */}
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      <meta property="og:image:alt" content={ogImgTitle} />

      {ogImgSrc && <meta name="image" property="og:image" content={ogImgSrc} />}
      <meta property="og:image:width" content={ogImgWidth} />
      <meta property="og:image:height" content={ogImgHeight} />

      {/* <meta property="og:url"> tag is added at build time (see gatsby-ssr.js) */}

      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {ogImgSrc && <meta name="twitter:image" content={ogImgSrc} />}
      <meta name="twitter:site" content="@flexport" />

      {/* hrefLang tags are added at build time (see gatsby-ssr.js) */}
    </Helmet>
  );
};

export default HeaderInfo;
