// @flow

import * as React from "react";
import {graphql} from "gatsby";
import {css, StyleSheet} from "aphrodite";
import {useContext, useState} from "react";
import validator from "email-validator";
import {useIntl} from "gatsby-plugin-react-intl";
import {COUNTRY_OPTIONS} from "../constants";
import dropdownImg from "../assets/dropdown.svg";
import {MEDIUM_FONT_FAMILY} from "../styles/typography";
import {
  BLACK,
  GRAY,
  SEAFOAM_5,
  RED,
  TEXT_BLACK,
  TEXT_PLACEHOLDER,
  TEXT_WHITE,
  VERY_LIGHT_GREY,
  WHITE,
} from "../styles/colors";
import {SIDE_MARGIN, SIDE_MARGIN_WIDE, BREAKPOINTS} from "../styles/sizes";

import Button from "./Button";
import Checkbox from "./Checkbox";
import GDPRContext from "./gdpr/GDPRContext";
import Layout from "../container/Layout";

type formDataField = {
  value: string,
  isInvalid: boolean,
};

type formDataType = {
  firstName: formDataField,
  lastName: formDataField,
  company: formDataField,
  email: formDataField,
  country: formDataField,
  organizationType: formDataField,
  serviceRequested: formDataField,
};

const formBaseState = {
  firstName: {
    value: "",
    isInvalid: false,
  },
  lastName: {
    value: "",
    isInvalid: false,
  },
  company: {
    value: "",
    isInvalid: false,
  },
  email: {
    value: "",
    isInvalid: false,
  },
  country: {
    value: "",
    isInvalid: false,
  },
  organizationType: {
    value: "",
    isInvalid: false,
  },
  serviceRequested: {
    value: "",
    isInvalid: false,
  },
};

type Props = {
  formHandlerURL?: ?string,
  campaign?: ?string,
};

const DEFAULT_FORM_HANDLER =
  "https://tech.flexport.com/l/925323/2021-07-07/43vw";

function ContactForm(props: Props): React.Node {
  const formHandlerURL = props.formHandlerURL || DEFAULT_FORM_HANDLER;
  const campaign = props.campaign;

  const {isEU} = useContext(GDPRContext);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [GDPRAccepted, setGDPRAccepted] = useState(!isEU); // if it's EU, then it'll always start and stay true
  const [formData, setFormData]: [formDataType, Function] =
    useState(formBaseState);
  const intl = useIntl();

  const organizationOptions = [
    {
      label: intl.formatMessage({id: "contact_form_organization_corporation"}),
      value: "Corporation",
    },
    {
      label: intl.formatMessage({id: "contact_form_organization_EU_entity"}),
      value: "EU Registered Entity with a VAT number",
    },
    {
      label: intl.formatMessage({id: "contact_form_organization_LLC"}),
      value: "Limited Liability Company",
    },
    {
      label: intl.formatMessage({id: "contact_form_organization_LLP"}),
      value: "Limited Liability Partnership",
    },
    {
      label: intl.formatMessage({id: "contact_form_organization_none"}),
      value: "Not registered yet",
    },
    {
      label: intl.formatMessage({id: "contact_form_organization_partnership"}),
      value: "Partnership",
    },
    {
      label: intl.formatMessage({id: "contact_form_organization_sole"}),
      value: "Sole Proprietorship",
    },
    {
      label: intl.formatMessage({id: "contact_form_organization_ngo"}),
      value: "NGO / Nonprofit",
    },
  ];

  const serviceOptions = [
    {
      label: intl.formatMessage({id: "contact_form_service_disaster_relief"}),
      value: "Disaster relief / Emergency response shipping support",
    },
    {
      label: intl.formatMessage({id: "contact_form_service_shipping"}),
      value: "General shipping support / Moving product donations",
    },
    {
      label: intl.formatMessage({
        id: "contact_form_service_calculate_emissions",
      }),
      value: "Calculating carbon emissions",
    },
    {
      label: intl.formatMessage({id: "contact_form_service_offset_emissions"}),
      value: "Offsetting carbon emissions",
    },
    {
      label: intl.formatMessage({id: "contact_form_service_API"}),
      value: "API access to calculate emissions from shipping",
    },
    {
      label: intl.formatMessage({id: "contact_form_service_reduce_emissions"}),
      value: "Reducing emissions through alternative fuel programs",
    },
  ];

  const validate = e => {
    const field = e.target.name;
    const {value} = e.target;

    const newFormData: formDataType = {...formData};

    if (!value) {
      newFormData[field].isInvalid = true;
    } else if (field === "email") {
      newFormData[field].isInvalid = !validator.validate(value);
    } else {
      newFormData[field].isInvalid = false;
    }
    setFormData(newFormData);
  };

  const handleChange = e => {
    const newFormData: formDataType = {...formData};
    newFormData[e.target.name].value = e.target.value;
    setFormData(newFormData);
    validate(e);
  };

  const handleSubmit = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const formHasErrors = Object.keys(formData).reduce((state, field) => {
      if (!formData[field].value) {
        const newFormData: formDataType = {...formData};
        newFormData[field].isInvalid = true;
        setFormData(newFormData);
        return true;
      }
      if (formData[field].isInvalid) {
        return true;
      }
      return state;
    }, false);

    if (!formHasErrors && termsAccepted) {
      /* $FlowIgnore[incompatible-use] form is optional on HTMLButtonElement */
      e.currentTarget.form.submit();
    }
  };

  return (
    <Layout slug="contact">
      <div className={css(styles.container)}>
        <form
          className={css(styles.form)}
          action={formHandlerURL}
          method="post"
        >
          <div className={css(styles.formHead)}>
            <h1 className={css(styles.title)}>
              {intl.formatMessage({id: "contact_title"})}
            </h1>
            <p className={css(styles.subTitle)}>
              {intl.formatMessage({id: "contact_sub_title"})}
            </p>
          </div>
          <div className={css(styles.formBody)}>
            <div className={css(styles.formField)}>
              <div className={css(styles.formLabel)}>
                {intl.formatMessage({id: "contact_form_first_name"})}
              </div>
              <input
                name="firstName"
                placeholder="Jane"
                value={formData.firstName.value}
                onChange={handleChange}
                className={css(
                  styles.formInput,
                  formData.firstName.isInvalid && styles.invalid
                )}
                onBlur={validate}
              />
            </div>
            <div className={css(styles.formField)}>
              <div className={css(styles.formLabel)}>
                {intl.formatMessage({id: "contact_form_last_name"})}
              </div>
              <input
                name="lastName"
                placeholder="Smith"
                value={formData.lastName.value}
                onChange={handleChange}
                className={css(
                  styles.formInput,
                  formData.lastName.isInvalid && styles.invalid
                )}
                onBlur={validate}
              />
            </div>
            <div className={css(styles.formField)}>
              <div className={css(styles.formLabel)}>
                {intl.formatMessage({id: "contact_form_company"})}
              </div>
              <input
                name="company"
                value={formData.company.value}
                onChange={handleChange}
                placeholder="Example: Company Inc."
                className={css(
                  styles.formInput,
                  formData.company.isInvalid && styles.invalid
                )}
                onBlur={validate}
              />
            </div>
            <div className={css(styles.formField)}>
              <div className={css(styles.formLabel)}>
                {intl.formatMessage({id: "contact_form_email"})}
              </div>
              <input
                name="email"
                value={formData.email.value}
                onChange={handleChange}
                placeholder="jane@example.com"
                className={css(
                  styles.formInput,
                  formData.email.isInvalid && styles.invalid
                )}
                onBlur={validate}
              />
            </div>
            <div className={css(styles.formField)}>
              <div className={css(styles.formLabel)}>
                {intl.formatMessage({id: "contact_form_country"})}
              </div>
              <div
                className={css(
                  styles.selectOuter,
                  formData.country.isInvalid && styles.invalid
                )}
              >
                <select
                  name="country"
                  className={css(styles.selectInner)}
                  required
                  onChange={handleChange}
                  value={formData.country.value}
                  autoComplete="country"
                >
                  <option value="" disabled>
                    {intl.formatMessage({id: "contact_form_country_select"})}
                  </option>
                  {COUNTRY_OPTIONS.map(({value, label}) => (
                    <option key={value} value={label}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={css(styles.formField)}>
              <div className={css(styles.formLabel)}>
                {intl.formatMessage({id: "contact_form_org_type"})}
              </div>
              <div
                className={css(
                  styles.selectOuter,
                  formData.organizationType.isInvalid && styles.invalid
                )}
              >
                <select
                  name="organizationType"
                  className={css(styles.selectInner)}
                  required
                  onChange={handleChange}
                  value={formData.organizationType.value}
                >
                  <option value="" disabled>
                    {intl.formatMessage({id: "contact_form_org_type_select"})}
                  </option>
                  {organizationOptions.map(({value, label}) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={css(styles.formField)}>
              <div className={css(styles.formLabel)}>
                {intl.formatMessage({id: "contact_form_service"})}
              </div>
              <div
                className={css(
                  styles.selectOuter,
                  formData.serviceRequested.isInvalid && styles.invalid
                )}
              >
                <select
                  name="serviceRequested"
                  className={css(styles.selectInner)}
                  required
                  onChange={handleChange}
                  value={formData.serviceRequested.value}
                >
                  <option value="" disabled>
                    {intl.formatMessage({id: "contact_form_service_select"})}
                  </option>
                  {serviceOptions.map(({value, label}) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={css(styles.formField)}>
              <div className={css(styles.tac)}>
                <Checkbox
                  name="termsAccepted"
                  active={termsAccepted}
                  onClick={() => {
                    setTermsAccepted(!termsAccepted);
                  }}
                />
                <div
                  className="dangerouslySetContentStyle"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({id: "contact_form_terms"}),
                  }}
                />
              </div>
              {isEU && (
                <div className={css(styles.tac)}>
                  <Checkbox
                    name="gdprAccepted"
                    active={GDPRAccepted}
                    onClick={() => {
                      setGDPRAccepted(!GDPRAccepted);
                    }}
                  />
                  <div
                    className="dangerouslySetContentStyle"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({id: "contact_form_gdpr"}),
                    }}
                  />
                </div>
              )}
            </div>
            {campaign && (
              <input
                name="campaign"
                value={campaign}
                type="hidden"
                className={css(styles.hidden)}
              />
            )}
            <div className={css(styles.formField, styles.submit)}>
              <Button
                text={intl.formatMessage({id: "contact_form_submit"})}
                componentStyle="green"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    alignItems: "center",
    background: VERY_LIGHT_GREY,
    padding: 0,
    minHeight: "calc(100vh - 105px)",
    [BREAKPOINTS.DESKTOP]: {
      padding: `10vh ${SIDE_MARGIN}`,
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `10vh ${SIDE_MARGIN_WIDE}`,
    },
  },
  form: {
    background: WHITE,
    borderRadius: "unset",
    boxShadow: "unset",

    [BREAKPOINTS.DESKTOP]: {
      borderRadius: "6px",
      boxShadow:
        "0 50px 100px -20px rgb(50 50 93 / 5%), 0 30px 60px -30px rgb(0 0 0 / 5%), 0 -18px 60px -10px rgb(0 0 0 / 1%)",
    },
  },
  formHead: {
    background: SEAFOAM_5,
    color: WHITE,
    padding: "25px",
    borderRadius: "unset",
    boxSizing: "border-box",
    [BREAKPOINTS.DESKTOP]: {
      borderRadius: "6px 6px 0px 0px",
    },
  },
  title: {
    fontSize: "28px",
    margin: "0px 0px",
  },
  subTitle: {
    margin: "5px 0px 0px",
    color: TEXT_WHITE,
  },
  formBody: {
    padding: "15px 25px 25px 25px",
    boxSizing: "border-box",
    [BREAKPOINTS.DESKTOP]: {
      padding: "35px 45px 25px 45px",
    },
  },
  formField: {
    padding: "10px 0px 5px 0px",
    display: "flex",
    flexDirection: "column",
    [BREAKPOINTS.DESKTOP]: {
      flexDirection: "row",
    },
  },
  formLabel: {
    fontFamily: [MEDIUM_FONT_FAMILY],
    display: "flex",
    alignItems: "center",
    padding: "0px 25px 5px 0px",
    fontSize: "16px",
    color: TEXT_BLACK,
    paddingTop: "4px",
    width: "unset",
    [BREAKPOINTS.DESKTOP]: {
      width: "200px",
    },
  },
  formInput: {
    width: "unset",
    boxSizing: "border-box",
    background: VERY_LIGHT_GREY,
    padding: "8px 15px",
    borderRadius: "4px",
    border: "none",
    fontSize: "16px",
    outline: "none",
    maxHeight: "45px",
    borderBottom: `3px solid ${WHITE}`,
    "::placeholder": {
      color: GRAY,
    },
    ":-webkit-autofill": {
      transition: "background-color 100000000s",
    },
    [BREAKPOINTS.DESKTOP]: {
      width: "500px",
    },
  },
  invalid: {
    borderBottom: `3px solid ${RED}`,
  },
  submit: {
    paddingTop: "25px",
    justifyContent: "flex-end",
  },
  hidden: {display: "none"},
  selectOuter: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    background: VERY_LIGHT_GREY,
    padding: "8px 15px",
    maxHeight: "45px",
    borderRadius: "4px",
    outline: "none",
    borderBottom: `3px solid ${WHITE}`,
    width: "unset",
    "::after": {
      content: '""',
      position: "absolute",
      width: "9px",
      height: "5px",
      right: "20px",
      top: "50%",
      marginTop: "-2px",
      backgroundImage: `url(${dropdownImg})`,
      pointerEvents: "none",
    },
    [BREAKPOINTS.DESKTOP]: {
      width: "500px",
    },
  },
  selectInner: {
    borderStyle: "none",
    outline: "none",
    appearance: "none",
    color: BLACK,
    position: "relative",
    cursor: "pointer",
    fontSize: "16px",
    background: "transparent",
    maxWidth: "100%",
    paddingRight: "20px",
    width: "100%",
    ":required:invalid": {
      color: TEXT_PLACEHOLDER,
    },
    ":focus": {
      color: BLACK,
    },
    ":-webkit-autofill": {
      transition: "background-color 100000000s",
    },
  },
  tac: {
    display: "flex",
    alignItems: "flex-start",
    fontSize: "14px",
    width: "unset",
    margin: "10px 0px",
    [BREAKPOINTS.DESKTOP]: {
      marginLeft: "225px",
      width: "500px",
    },
  },
});

export const contactPageGlobalStyles: mixed = {
  ".react-dropdown-select": {
    width: "unset",

    [BREAKPOINTS.DESKTOP]: {
      width: "500px",
    },
  },
  ".react-dropdown-select-input": {
    margin: "0px",
    "::placeholder": {
      color: GRAY,
      fontSize: "16px",
    },
  },
};

export default ContactForm;
