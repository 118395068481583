// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import Image from "./Image.jsx";
import MarkDown from "./MarkDown";

import {
  BLACK,
  LIME_4,
  OFF_WHITE,
  MIDNIGHT_BLUE,
  TEXT_BLACK,
} from "../../styles/colors";
import {
  MEDIUM_FONT_FAMILY,
  REGULAR_EXTENDED_FONT_FAMILY,
  p,
} from "../../styles/typography";
import {
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
  BREAKPOINTS,
} from "../../styles/sizes";

import type {
  ContentfulImageFixed,
  ContentfulImageFluid,
} from "../../types/fragments/ContentfulImageFragment";

type Props = {
  body?: string,
  authorName?: string,
  authorRole?: string,
  logo?: ContentfulImageFixed | ContentfulImageFluid,
};

function Quote(props: Props): React.Node {
  const {body, logo, authorName, authorRole} = props;
  return (
    <div className={css(styles.section)}>
      <div className={css(styles.quoteWrapper)}>
        <q className={css(styles.quoteIcon)} />
      </div>
      {body && <MarkDown decorations={styles.quoteBody} body={`"${body}"`} />}

      <div className={css(styles.client)}>
        {logo && (
          <div className={css(styles.logo)}>
            <Image image={logo} />
          </div>
        )}

        <div className={css(styles.person)}>
          {authorName && <div className={css(styles.name)}>{authorName}</div>}
          {authorRole && <div>{authorRole}</div>}
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  section: {
    background: OFF_WHITE,
    color: MIDNIGHT_BLUE,
    padding: `45px ${SIDE_MARGIN_MOBILE}`,
    [`${BREAKPOINTS.DESKTOP}`]: {
      padding: `85px ${SIDE_MARGIN}`,
    },
    [`${BREAKPOINTS.DESKTOP_LARGE}`]: {
      padding: `85px ${SIDE_MARGIN_WIDE}`,
    },
  },
  quoteWrapper: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    background: LIME_4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  quoteIcon: {
    color: BLACK,
    fontSize: "45px",
    height: "4px",
    quotes: "'“' '”' '‘' '‘'",
    ":after": {
      display: "none",
      visibility: "hidden",
    },
  },
  quoteBody: {
    ...p,
    fontFamily: REGULAR_EXTENDED_FONT_FAMILY,
    fontSize: "24px",
    lineHeight: "28px",
    padding: "15px 0px 35px",
  },
  client: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "100px",
    objectFit: "contain",
    marginRight: "15px",
    height: "auto",
  },
  person: {
    borderLeft: `1px solid ${TEXT_BLACK}`,
    paddingLeft: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: "16px",
  },
  name: {
    fontFamily: [MEDIUM_FONT_FAMILY],
    fontWeight: 900,
  },
});

export default Quote;
