// @flow
import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import Card from "./Card";
import FastFact from "./FastFact";
import StatisticCard from "./StatisticCard";
import PartnerCard from "./PartnerCard";

import {
  DARK_GREY_2,
  WHITE,
  SEAFOAM_5,
  TEAL_4,
  MIDNIGHT_BLUE,
} from "../../styles/colors";
import {h2, p, MEDIUM_FONT_FAMILY} from "../../styles/typography";
import {
  SIDE_MARGIN,
  SIDE_MARGIN_MOBILE,
  SIDE_MARGIN_WIDE,
  BREAKPOINTS,
} from "../../styles/sizes";

import {type CssSettings} from "../../types/types/cssSettings";
import {type ContentfulCardFragment} from "../../types/__generated__/ContentfulCardFragment";
import {type ContentfulFastFactFragment} from "../../types/__generated__/ContentfulFastFactFragment";
import {type ContentfulStatisticCardFragment} from "../../types/__generated__/ContentfulStatisticCardFragment";
import {type ContentfulPartnerCardFragment} from "../../types/__generated__/ContentfulPartnerCardFragment";

type Props = {|
  +headline?: ?string,
  +description?: ?string,
  +showMoreButtonLabel?: ?string,
  +cards: Array<
    | ContentfulFastFactFragment
    | ContentfulCardFragment
    | ContentfulStatisticCardFragment
    | ContentfulPartnerCardFragment
  >,
|};

const ITEMS_PER_LOAD = 3;

function LazyLoadMultiColumn(props: Props): React.Node {
  const [page, setPage] = React.useState(0);

  const {headline, description, cards, showMoreButtonLabel} = props;

  const totalCards = cards.length;

  const loadMore = () => {
    const currentCardShown = (page + 1) * ITEMS_PER_LOAD;
    if (totalCards - currentCardShown > 0) {
      setPage(page + 1);
    }
  };

  const cardToShow = [...cards].splice(0, (page + 1) * ITEMS_PER_LOAD);

  const hasBalance = cards.length - cardToShow.length;

  return (
    <section className={css(styles.section)}>
      {headline && <h2 className={css(styles.headline)}>{headline}</h2>}
      {description && <p className={css(styles.description)}>{description}</p>}

      <div className={css(styles.colContainer)}>
        {cardToShow.map((card, index) => {
          let component = null;
          switch (card.__typename) {
            case "ContentfulCard":
              return <Card card={card} key={index} />;
            case "ContentfulFastFact":
              return <FastFact fastFact={card} key={index} />;
            case "ContentfulStatisticCard":
              return <StatisticCard card={card} key={index} />;
            case "ContentfulPartnerCard": {
              return <PartnerCard card={card} />;
            }
            default:
              return null;
          }
        })}
      </div>

      {hasBalance > 0 && (
        <button
          onClick={loadMore}
          type="text"
          className={css(styles.showMoreButtonLabel)}
        >
          {showMoreButtonLabel}
        </button>
      )}
    </section>
  );
}

const styles = StyleSheet.create({
  section: {
    background: WHITE,
    padding: `45px ${SIDE_MARGIN_MOBILE}`,
    [BREAKPOINTS.DESKTOP]: {
      padding: `85px ${SIDE_MARGIN}`,
    },
    [BREAKPOINTS.DESKTOP_LARGE]: {
      padding: `85px ${SIDE_MARGIN_WIDE}`,
    },
  },
  headline: {
    ...h2,
    marginBottom: "8px",
    color: MIDNIGHT_BLUE,
  },
  description: {
    ...p,
    fontSize: "16px",
    color: DARK_GREY_2,
    maxWidth: "680px",
  },
  colContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    gap: "36px",
    marginTop: "48px",

    [BREAKPOINTS.DESKTOP]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  showMoreButtonLabel: {
    display: "block",
    margin: "0 auto",
    marginTop: "48px",
    cursor: "pointer",
    color: MIDNIGHT_BLUE,
    fontSize: "16px",
    fontFamily: MEDIUM_FONT_FAMILY,
    padding: "8px 24px",
    borderRadius: "5px",
    border: `2px solid ${SEAFOAM_5}`,
    width: "100%",
    ":focus-visible": {
      outline: `3px solid ${TEAL_4}`,
      outlineOffset: "2px",
    },
    [BREAKPOINTS.DESKTOP]: {
      width: "fit-content",
    },
  },
});

export default LazyLoadMultiColumn;
