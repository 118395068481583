// @flow

import * as React from "react";
import {css, StyleSheet} from "aphrodite";

import Image from "./Image";
import CTAButton from "../CTAButton";
import MarkDown from "./MarkDown";

import {type CssSettings} from "../../types/types/cssSettings";
import {type ContentfulCardFragment} from "../../types/__generated__/ContentfulCardFragment";
import {BREAKPOINTS} from "../../styles/sizes";
import {WHITE} from "../../styles/colors";
import {h3, p} from "../../styles/typography";

type Props = {
  card: ContentfulCardFragment,
  cardContainerClass?: CssSettings,
  cardImageClass?: CssSettings,
  cardHeadlineClass?: CssSettings,
  cardBodyClass?: CssSettings,
  cardCTAClass?: CssSettings,
};

function Card(props: Props): React.Node {
  const {
    card,
    cardContainerClass,
    cardImageClass,
    cardHeadlineClass,
    cardBodyClass,
    cardCTAClass,
  } = props;
  return (
    <div className={css(styles.container, cardContainerClass)}>
      {card.image && (
        <Image
          image={card.image}
          imageWrapperDecorations={styles.image}
          objectFit="contain"
        />
      )}
      {card.headline && (
        <div className={css(styles.headline, cardHeadlineClass)}>
          {card.headline}
        </div>
      )}
      {card.body && (
        <MarkDown
          decorations={
            cardBodyClass ? [styles.body, cardBodyClass] : styles.body
          }
          body={card.body?.body || ""}
        />
      )}
      {card.cta && (
        <div className={css(styles.CTA, cardCTAClass)}>
          <CTAButton
            text={card.cta?.label || ""}
            link={card.cta?.link || ""}
            amplitudeCtaName={card.cta?.amplitudeCtaName || ""}
          />
        </div>
      )}
    </div>
  );
}
export default Card;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    padding: "0px",
    marginTop: "24px",
    minWidth: "100%",
    [`${BREAKPOINTS.DESKTOP}`]: {
      minWidth: "unset",
      width: "unset",
      maxWidth: "33%",
      flex: "1 1 0",
    },
  },
  image: {
    width: "110px",
    height: "auto",
  },
  headline: {
    ...h3,
    margin: "40px 0px 15px 0px",
    color: WHITE,
  },
  body: {
    ...p,
    color: WHITE,
  },
  CTA: {
    paddingTop: "15px",
    width: "100%",
    marginTop: "25px",
    [`${BREAKPOINTS.DESKTOP}`]: {
      marginTop: "auto",
    },
  },
});
