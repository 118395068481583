// @flow
import GTAmericaExtendedBold from "./fonts/GT-America-Extended-Bold.woff2";
import GTAmericaExtendedRegular from "./fonts/GT-America-Extended-Regular.woff2";
import GTAmericaStandardMedium from "./fonts/GT-America-Standard-Medium.woff2";
import GTAmericaStandardRegular from "./fonts/GT-America-Standard-Regular.woff2";
import GTAmericaStandardBold from "./fonts/GT-America-Standard-Bold.woff2";

import GTAmericaExtendedBoldWoff from "./fonts/GT-America-Extended-Bold.woff";
import GTAmericaExtendedRegularWoff from "./fonts/GT-America-Extended-Regular.woff";
import GTAmericaStandardMediumWoff from "./fonts/GT-America-Standard-Medium.woff";
import GTAmericaStandardRegularWoff from "./fonts/GT-America-Standard-Regular.woff";
import GTAmericaStandardBoldWoff from "./fonts/GT-America-Standard-Bold.woff";

import {MAX_MOBILE_WIDTH} from "./sizes";

export const fontExtendedBold = {
  fontFamily: "GT-America-Extended-Bold",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `url('${GTAmericaExtendedBold}') format('woff2'), url('${GTAmericaExtendedBoldWoff}') format('woff')`,
  fontDisplay: "swap",
};

export const fontExtendedRegular = {
  fontFamily: "GT-America-Extended-Regular",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `url('${GTAmericaExtendedRegular}') format('woff2'), url('${GTAmericaExtendedRegularWoff}') format('woff')`,
  fontDisplay: "swap",
};

export const fontStandardMedium = {
  fontFamily: "GT-America-Standard-Medium",
  fontStyle: "normal",
  fontWeight: "normal",
  letterSpacing: "1.2px",
  src: `url('${GTAmericaStandardMedium}') format('woff2'), url('${GTAmericaStandardMediumWoff}') format('woff')`,
  fontDisplay: "swap",
};

export const fontStandardRegular = {
  fontFamily: "GT-America-Standard-Regular",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `url('${GTAmericaStandardRegular}') format('woff2'), url('${GTAmericaStandardRegularWoff}') format('woff')`,
  fontDisplay: "swap",
};

export const fontStandardBold = {
  fontFamily: "GT-America-Standard-Bold",
  fontStyle: "normal",
  fontWeight: "normal",
  fontDisplay: "swap",
  src: `url('${GTAmericaStandardBold}') format('woff2'), url('${GTAmericaStandardBoldWoff}') format('woff')`,
};

export const MEDIUM_FONT_FAMILY = [
  fontStandardMedium,
  "Trebuchet MS",
  "sans-serif",
];
export const REGULAR_FONT_FAMILY = [
  fontStandardRegular,
  "Trebuchet MS",
  "sans-serif",
];

export const BOLD_EXTENDED_FONT_FAMILY = [
  fontExtendedBold,
  "Trebuchet MS",
  "sans-serif",
];

export const REGULAR_EXTENDED_FONT_FAMILY = [
  fontExtendedRegular,
  "Trebuchet MS",
  "sans-serif",
];

/* $FlowIgnore[signature-verification-failure] flow does not like the complex @media key */
export const h1 = {
  fontFamily: [fontExtendedRegular, "Arial", "sans-serif"],
  fontSize: "40px",
  lineHeight: "48px",
  fontWeight: "bold",
  [`@media (max-width: ${MAX_MOBILE_WIDTH})`]: {
    fontSize: "28px",
    lineHeight: "42px",
  },
};

/* $FlowIgnore[signature-verification-failure] flow does not like the complex @media key */
export const h2 = {
  fontFamily: [fontExtendedRegular, "Arial", "sans-serif"],
  fontSize: "32px",
  lineHeight: "42px",
  marginBottom: "45px",
  fontWeight: "bold",
  [`@media (max-width: ${MAX_MOBILE_WIDTH})`]: {
    fontSize: "24px",
    lineHeight: "36px",
  },
};

export const h3 = {
  fontFamily: MEDIUM_FONT_FAMILY,
  fontSize: "18px",
  lineHeight: "25px",
  fontWeight: 900,
  marginBottom: "45px",
};

export const p = {
  fontSize: "18px",
  fontFamily: REGULAR_FONT_FAMILY,
  lineHeight: "25px",
  fontWeight: 100,
  margin: "0px",
};

export const eyebrow = {
  fontSize: "12px",
  lineHeight: "14px",
  fontFamily: BOLD_EXTENDED_FONT_FAMILY,
  textTransform: "uppercase",
  letterSpacing: "1px",
  margin: 0,
};
