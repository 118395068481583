// @flow
import * as React from "react";
import {useEffect} from "react";

import {getCurrentLocationPath} from "../utils/locationUtils";

export default function ScrollToTop(): React.Node {
  const pathname = getCurrentLocationPath();

  useEffect(() => window.scrollTo(0, 0), [pathname]);

  return null;
}
